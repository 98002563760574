import {
	Row,
	Col,
	Card,
	Button,
	Container,
	Breadcrumb,
	Form,
	Table,
	Alert,
	Modal,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect, useRef, startTransition, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import styles from "./ManageSOP.module.css";
import axios from "axios";
import {
	URI_ADMIN_BUNDLE_ACTIVATE,
	URI_ADMIN_BUNDLE_DEACTIVATE,
	URI_ADMIN_GROUP_ACTIVATE,
	URI_ADMIN_GROUP_DEACTIVATE,
	URI_ADMIN_SOP,
	URI_ADMIN_SOPS,
	URI_ADMIN_SOP_BUNDLES,
	URI_ADMIN_SOP_GROUPS,
	URI_ADMIN_SOP_PAGE,
} from "../../constants";
import { SOP_ADD, SOP_EDIT } from "../../pageLocations";
import BundleDetails from "./BundleDetails";
import { useLoader } from "../../hooks/useLoader";

function ManageSOP() {
	const navigate = useNavigate();

	const [sops, setSops] = useState([]);
	const sopResultset = useRef([]);
	const [bundles, setBundles] = useState([]);
	const bundlesResutset = useRef([]);
	const [selectedBundleId, setSelectedBundled] = useState(0);
	const selectedGroupId = useRef(0);
	const [groups, setGroups] = useState([]);

	const [open, setOpen] = useState(true);
	const [showAlert, setShowAlert] = useState(-1);
	const [showBundle, setShowBundle] = useState(false);
	const [editBundle, setEditBundle] = useState({});

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const deleteSop = useRef({});

	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const { startLoader, stopLoader } = useLoader();

	useEffect(() => {
		// console.log("this is a test....!!!");
		populateData();
	}, []);

	const populateData = async () => {
		startLoader();
		let pr1 = fetchSops();
		let pr2 = fetchBundles();
		let pr3 = fetchGroups();

		Promise.allSettled([pr1, pr2, pr3]).then(() => stopLoader());
	};

	const fetchSops = () => {
		return axios
			.get(URI_ADMIN_SOPS, config)
			.then((response) => {
				console.log(response);

				let s = [];
				if (response.status === 200) {
					s = response.data ? response.data : [];
				}
				setSops(s);
				sopResultset.current = s;
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
				setSops([]);
				sopResultset.current = [];
			});
	};

	const fetchBundles = () => {
		return axios
			.get(URI_ADMIN_SOP_BUNDLES, config)
			.then((response) => {
				console.log("sop_bundles : " + response);

				let bdls = [];
				if (response.status === 200) {
					bdls = response.data.data.sop_details ? response.data.data.sop_details : [];
				}
				setBundles(bdls);
				bundlesResutset.current = bdls;
				localStorage.setItem("sopBundles", JSON.stringify(bdls));
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
				bundlesResutset.current = [];
				localStorage.setItem("sopBundles", JSON.stringify([]));
			});
	};

	const fetchGroups = () => {
		return axios
			.get(URI_ADMIN_SOP_GROUPS, config)
			.then((response) => {
				console.log("sop_groups : " + response);
				let grps = [];
				if (response.status === 200) {
					grps = response.data ? response.data : [];
					grps = grps.sort((a, b) => (parseInt(a.title.split(".")[0]) > parseInt(b.title.split(".")) ? 1 : -1));
				}
				setGroups(grps);
				localStorage.setItem("sopGroups", JSON.stringify(grps));
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
				setGroups([]);
				localStorage.setItem("sopGroups", JSON.stringify([]));
			});
	};

	const fetchSopPage = (sopId) => {
		return axios.get(URI_ADMIN_SOP_PAGE(sopId, 1), config);
	};

	const handleBundleSearchChange = (text) => {
		let bdls = bundlesResutset.current;
		console.log(bdls);
		if (text) {
			let subset = bdls.filter((b) => b.title.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setBundles(subset);
		} else {
			setBundles(bdls);
		}
	};

	const handleSopSearchChange = (text) => {
		let sps = sopResultset.current;
		if (selectedBundleId !== 0) {
			sps = sps.filter((s) => s.sop_bundle_id === selectedBundleId);
		}
		if (selectedGroupId.current !== -1 && selectedGroupId.current !== 0) {
			sps = sps.filter((s) => s.sop_group_id === selectedGroupId.current);
		}
		if (text) {
			let subset = sps.filter((s) => s.title.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setSops(subset);
		} else {
			setSops(sps);
		}
	};

	const handleBundleClick = (index, groupId) => {
		let { id } = bundles[index];
		let filterredSops = [];
		if (id === selectedBundleId && groupId === -1) {
			filterredSops = sopResultset.current;
		} else {
			sopResultset.current.map((s) => {
				let { sop_bundle_id, sop_group_id } = s;
				if (groupId !== -1) {
					if (groupId === sop_group_id && id === sop_bundle_id) {
						filterredSops.push(s);
					}
				} else {
					if (id === sop_bundle_id) {
						filterredSops.push(s);
					}
				}
				return s;
			});
		}
		setSops(filterredSops);
		setSelectedBundled(groupId === -1 && selectedBundleId === id ? 0 : id);
		selectedGroupId.current = groupId;
	};

	const handleEditSopClick = async (sopIndex) => {
		let sop = sops[sopIndex];
		await updateSopContext(sop);

		startTransition(() => {
			navigate(SOP_EDIT);
		});
	};

	const handleAddSopClick = () => {
		updateSopContext();
		startTransition(() => {
			navigate(SOP_ADD);
		});
	};

	const handleDeleteSopClick = (sopId) => {
		setShowDeleteConfirmation(false);
		axios
			.delete(URI_ADMIN_SOP + "/" + sopId, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					sopResultset.current = sopResultset.current.filter((s) => s.id !== sopId);
					setSops(sops.filter((s) => s.id !== sopId));
					setShowAlert(1);
				} else {
					setShowAlert(2);
				}
			})
			.catch((err) => {
				setShowAlert(2);
				console.log(err);
			});
	};

	const handleSopActiveStatusChange = (sopIndex) => {
		let editSop = sops[sopIndex];
		let payload = { ...editSop, active: !editSop.active };
		axios
			.put(URI_ADMIN_SOP + "/" + payload.id, payload, config)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => console.log(err));

		let newSops = sops.map((s, idx) => {
			if (idx === sopIndex) {
				return { ...s, active: !s.active };
			} else {
				return { ...s };
			}
		});
		setSops(newSops);
	};

	const updateSopContext = async (sop) => {
		const addEmptySopPage = (sop) => {
			let headerBlocks = [
				{ title: "Scope", text_content: "" },
				{ title: "Timeline", text_content: "" },
				{ title: "Documents", text_content: "" },
				{ title: "Responsibility", text_content: "" },
			];
			let bodyBlocks = [];
			for (let i = 0; i < sop.count_body_blocks; i++) {
				bodyBlocks.push({});
			}
			sop.count_header_blocks = 4;
			sop.page = { sop_document_id: sop.id, page_num: 0, sop_header_blocks: headerBlocks, sop_body_blocks: bodyBlocks };
			sop.pages = 1;
		};

		if (sop && sop.id) {
			await fetchSopPage(sop.id)
				.then((response) => {
					console.log("sopPage : " + response);
					// if (response.status === 200) {
					let sopPage = response.data;
					if (sopPage && sopPage.id && sopPage.id > 1) {
						sop.page = sopPage;
					} else {
						addEmptySopPage(sop);
					}
					localStorage.setItem("sopContext", JSON.stringify(sop));
					// }
				})
				.catch((error) => {
					var error_received = "";
					// console.log(error);
					if (error.response) {
						error_received = error.response.data;
					}
					console.log("my error: ", error_received);

					addEmptySopPage(sop);
					localStorage.setItem("sopContext", JSON.stringify(sop));
				});
		} else {
			// addEmptySopPage({});
			localStorage.setItem("sopContext", JSON.stringify({}));
		}
	};

	function ActiveBundleSwitch({ bundleId }) {
		const handleStatusChange = (status) => {
			let url;
			if (status) {
				url = URI_ADMIN_BUNDLE_ACTIVATE + "?sop_bundle_id=" + bundleId;
			} else {
				url = URI_ADMIN_BUNDLE_DEACTIVATE + "?sop_bundle_id=" + bundleId;
			}
			axios
				.post(url, {}, config)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						setBundles(
							bundles.map((b) => {
								if (b.id === bundleId) {
									b = { ...b, active: status };
								}
								return b;
							})
						);
						setShowAlert(1);
					} else {
						setShowAlert(2);
					}
				})
				.catch((err) => {
					console.log(err);
					setShowAlert(2);
				});
		};

		let component;
		let bn = bundles.filter((b) => b.id === bundleId)[0];
		if (bn.active) {
			component = (
				<Form.Check
					type='switch'
					id='custom-switch'
					checked
					onChange={(e) => {
						handleStatusChange(false);
						e.stopPropagation();
					}}
				/>
			);
		} else {
			component = (
				<Form.Check
					type='switch'
					id='custom-switch'
					onChange={(e) => {
						handleStatusChange(true);
						e.stopPropagation();
					}}
				/>
			);
		}
		return component;
	}

	function ActiveGroupSwitch({ groupId }) {
		const handleStatusChange = (status) => {
			let url;
			if (status) {
				url = URI_ADMIN_GROUP_ACTIVATE + "?sop_grp_id=" + groupId;
			} else {
				url = URI_ADMIN_GROUP_DEACTIVATE + "?sop_grp_id=" + groupId;
			}
			axios
				.post(url, {}, config)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						setGroups(
							groups.map((g) => {
								if (g.id === groupId) {
									g = { ...g, active: status };
								}
								return g;
							})
						);
						setShowAlert(1);
					} else {
						setShowAlert(2);
					}
				})
				.catch((err) => {
					console.log(err);
					setShowAlert(2);
				});
		};

		let component;
		let gr = groups.filter((g) => g.id === groupId)[0];
		if (gr.active) {
			component = <Form.Check type='switch' id='custom-switch' checked onChange={(e) => handleStatusChange(false)} />;
		} else {
			component = <Form.Check type='switch' id='custom-switch' onChange={(e) => handleStatusChange(true)} />;
		}
		return component;
	}

	return (
		<>
			<Navbar />
			<div className='g-0 home-top-padding mb-4'>
				<div>
					<Alert key='success' variant='success' show={showAlert === 1} dismissible onClose={() => setShowAlert(-1)}>
						Transaction successful !
					</Alert>
					<Alert key='danger' variant='danger' show={showAlert === 2} dismissible onClose={() => setShowAlert(-1)}>
						Transaction failed !
					</Alert>
				</div>
				<div className='innerHead'>
					<h1>Manage SOP Bundles</h1>
				</div>
				<Container fluid='true' className='manageSOP'>
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href='/Home'>Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>SOPs</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<h4>SOP Bundles</h4>
						</Col>
						<Col md={4} className='text-end'>
							<Button variant='primary' onClick={(e) => handleAddSopClick()}>
								Add SOP
							</Button>
						</Col>
					</Row>
					<Row className='position-relative'>
						<div className='exp-col-btn'>
							<Button onClick={() => setOpen(!open)} aria-controls='example-collapse-text' aria-expanded={open}>
								<i className='bi bi-chevron-right'></i>
							</Button>
						</div>
						<Collapse in={open}>
							<Col md={3} id='example-collapse-text'>
								<Card>
									<Card.Body>
										<Form className='mb-3'>
											<Form.Control
												type='search'
												placeholder='Search by  Bundle Name'
												className='search-icon me-2'
												aria-label='Search'
												maxLength='50'
												onChange={(e) => handleBundleSearchChange(e.target.value)}
											/>
										</Form>
										<div className='tree-menu-cont'>
											<ul>
												{bundles
													.sort((a, b) => (a.title > b.title ? 1 : -1))
													.map((b, idx) => {
														return (
															<Fragment key={idx}>
																<li key={b.id}>
																	<Row>
																		<OverlayTrigger
																			key={idx}
																			placement='right'
																			delay={{ show: 250, hide: 400 }}
																			overlay={<Tooltip>{b.title}</Tooltip>}
																		>
																			<Col
																				xs={8}
																				onClick={(e) => handleBundleClick(idx, -1)}
																				className='tree-main-folder'
																			>
																				<i className='bi bi-folder'></i> {b.title}
																			</Col>
																		</OverlayTrigger>
																		<Col xs={4} className='p-0'>
																			<div className='d-flex'>
																				<Button
																					variant='link'
																					onClick={(e) => {
																						setShowBundle(true);
																						setEditBundle(b);
																						e.stopPropagation();
																					}}
																				>
																					<i className='bi bi-pencil'></i>
																				</Button>
																				<Button variant='link'>
																					<i className='bi bi-trash'></i>
																				</Button>
																				<ActiveBundleSwitch bundleId={b.id} />
																			</div>
																		</Col>
																	</Row>
																</li>
																{groups
																	.sort((a, b) =>
																		parseInt(a.title.split(".")[0]) > parseInt(b.title.split(".")) ? 1 : -1
																	)
																	.filter((g) => g.sop_bundle_id === b.id)
																	.map((g) => {
																		return (
																			<OverlayTrigger
																				key={g.id}
																				placement='right'
																				delay={{ show: 250, hide: 400 }}
																				overlay={<Tooltip>{g.title}</Tooltip>}
																			>
																				<ul
																					style={g.sop_bundle_id === selectedBundleId ? {} : { display: "none" }}
																					key={g.id}
																					onClick={(e) => handleBundleClick(idx, g.id)}
																					className='tree-sop-file'
																				>
																					<li>
																						<i className='bi bi-file-earmark-text'></i> {g.title}
																						<ActiveGroupSwitch groupId={g.id} />
																					</li>
																				</ul>
																			</OverlayTrigger>
																		);
																	})}
															</Fragment>
														);
													})}
											</ul>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Collapse>
						<Col className='mb-3'>
							<div>
								<Form className='mt-3 mb-3'>
									<Form.Control
										type='search'
										placeholder='Search by SOP Name'
										className='search-icon me-2'
										aria-label='Search'
										maxLength='50'
										onChange={(e) => handleSopSearchChange(e.target.value)}
									/>
								</Form>
								<Table striped>
									<thead>
										<tr>
											<th>Bundle</th>
											<th>Group</th>
											<th>SOP Code</th>
											<th>Title</th>
											<th>Description</th>
											<th>Created By</th>
											<th>Created At</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{sops
											.sort((a, b) => (a.document_code > b.document_code ? 1 : -1))
											.map((sop, idx) => {
												return (
													<tr key={sop.id}>
														<td>{sop.bundle_title}</td>
														<td>{sop.group_title}</td>
														<td>{sop.document_code}</td>
														<td>{sop.title}</td>
														<td>{sop.description}</td>
														<td>
															{sop.created_by_first_name} {sop.created_by_last_name}
														</td>
														<td>
															{sop.created_at
																? new Date(sop.created_at).getDate() +
																  "/" +
																  (new Date(sop.created_at).getMonth() + 1) +
																  "/" +
																  new Date(sop.created_at).getFullYear()
																: ""}
														</td>
														<td>
															<div className='d-flex'>
																<Button variant='link' onClick={(e) => handleEditSopClick(idx)}>
																	<i className='bi bi-pencil'></i>
																</Button>{" "}
																<Button
																	variant='link'
																	onClick={(e) => {
																		deleteSop.current = sop;
																		setShowDeleteConfirmation(true);
																	}}
																>
																	<i className='bi bi-trash'></i>
																</Button>{" "}
																{sop.active ? (
																	<Form.Check
																		type='switch'
																		id='custom-switch'
																		checked
																		onChange={(e) => handleSopActiveStatusChange(idx)}
																	/>
																) : (
																	<Form.Check
																		type='switch'
																		id='custom-switch'
																		onChange={(e) => handleSopActiveStatusChange(idx)}
																	/>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />

			<Modal show={showDeleteConfirmation} onHide={(e) => setShowDeleteConfirmation(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>Do you really want to delete SOP "{deleteSop.current.title}" ? </Modal.Body>
				<Modal.Footer>
					Please note, Sop with attachments wont be deleted. Make sure you delete attachments and then delete Sop.
					<Button variant='secondary' onClick={(e) => setShowDeleteConfirmation(false)}>
						Cancel
					</Button>
					<Button variant='primary' onClick={(e) => handleDeleteSopClick(deleteSop.current.id)}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
			<BundleDetails
				show={showBundle}
				setShow={setShowBundle}
				newBundle={editBundle}
				setNewBundle={setEditBundle}
			></BundleDetails>
		</>
	);
}
export default ManageSOP;

import { Row, Col, Card, Button, Container, Breadcrumb, Form, Table } from "react-bootstrap";
import React from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
function SOPCountPerOrg() {
  return (
    <>
      <Navbar />
      <div className="g-0 home-top-padding mb-4">
        <div className="innerHead">
          <h1>Reports</h1>
        </div>
        <Container fluid="true" className="manageSOP">
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Reports - SOP Count per Org</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>SOP Count per Org</h4>
            </Col>
          </Row>
          <Row className="formGroup mb-4 mt-3">
            <Col md={4}>
              <Row>
                <Col>
                  <Form.Control
                    type="search"
                    placeholder="Search by Organization Name"
                    className="search-icon me-2"
                    aria-label="Search"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Organization Name</th>
                    <th>Total SOP</th>
                    <th>Deactivated SOP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>HR</td>
                    <td>Google</td>
                    <td>100</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>HR</td>
                    <td>Google</td>
                    <td>100</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>HR</td>
                    <td>Google</td>
                    <td>100</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>HR</td>
                    <td>Google</td>
                    <td>100</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>HR</td>
                    <td>Google</td>
                    <td>100</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>HR</td>
                    <td>Google</td>
                    <td>100</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default SOPCountPerOrg;

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export const SOPExpiry = ({sops}) => {
  return (
    <Card className="top-sops">
      <Container>
        <Row className="mb-3">
          <Col xs={7}>
            <h4>SOPs near expiry</h4>
          </Col>
          {/* <Col xs={5} className="text-end">
            <Card.Link href="#" className="float-end mt-1">
              View All
            </Card.Link>
          </Col> */}
        </Row>
        {sops.map((s, idx) => {
          return (
            <Row key={idx}>
              <Col xs={1}>
                <i className="bi bi-file-earmark-text"></i>
              </Col>
              <Col xs={7} className="mt-1">
                <div className="head">
                  <h5 className="mb-0">{/* Digital Consulting */}{s.company_name}</h5>valid till {s.valid_till}{/* Expires in 7 days */}
                </div>
              </Col>
              <Col xs={4} className="mt-3 text-end">
                {/* Bundle Name */}{s.title}
              </Col>
              <hr></hr>
            </Row>
          )
        })}
      </Container>
    </Card>
  );
};
export default SOPExpiry;

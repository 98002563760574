
// export function SopValidator() {
	const isTextValid = (text) => {
		return text && text.trim() !== "";
	}
	const isBundleIdValid = (sopState) => {
		return sopState.sop_bundle_id > 0;
	}
	const isBundleRatingValid = (bundleRating) => {
		return bundleRating ? bundleRating > 0 && bundleRating < 6 : false;
	}
	const isGroupIdValid = (sopState) => {
		return sopState.sop_group_id > 0;
	}
	const isTitleValid = (sopState) => {
		return sopState && sopState.title && sopState.title.trim() !== "";
	}
	const isDescriptionValid = (sopState) => {
		return sopState && sopState.description && sopState.description.trim() !== "";
	}
	const isCodeValid = (sopState) => {
		return sopState && sopState.document_code && sopState.document_code.trim() !== "";
	}
	const isSopBodyCountValid = (sopState) => {
		return sopState.count_body_blocks > 0 && sopState.count_body_blocks < 6;
	}
	const isDataValid = (sopState) => {
		return  isBundleIdValid(sopState) && isGroupIdValid(sopState) && isTitleValid(sopState) && isDescriptionValid(sopState) && isCodeValid(sopState) && isSopBodyCountValid(sopState);
	}
	const isPageDataValid = (sopState) => {
		let { sop_header_blocks, sop_body_blocks, id, update_summary } = sopState.page;
		let allHeaderContentValid = sop_header_blocks.reduce((acc, val) => acc && isTextValid(val.text_content), true);
		let allBodyTitleValid = sop_body_blocks.reduce((acc, val) => acc && isTextValid(val.title), true);
		let allBodyContentValid = sop_body_blocks.reduce((acc, val) => acc && isTextValid(val.text_content), true);
		let isUpdateSummaryValid = id && id > 0 ? isTextValid(update_summary) : true;
		return isSopBodySectionTitleValid(sopState) && allHeaderContentValid && allBodyTitleValid && allBodyContentValid && isUpdateSummaryValid;
	}
	
	const isSopHeaderTitleValid = (sopState, index) => {
		let head = sopState.page.sop_header_blocks[index];
		let text = head.title;
		return isTextValid(text);
	}
	const isSopHeaderContentValid = (sopState, index) => {
		let head = sopState.page.sop_header_blocks[index];
		let text = head.text_content;
		return isTextValid(text);
	}
	const isSopBodySectionTitleValid = (sopState) => {
		let text = sopState.body_section_title;
		return isTextValid(text);
	}
	const isSopBodyTitleValid = (sopState, index) => {
		let body = sopState.page.sop_body_blocks[index];
		let text = body.title;
		return isTextValid(text);
	}
	const isSopBodyContentValid = (sopState, index) => {
		let body = sopState.page.sop_body_blocks[index];
		let text = body.text_content;
		return isTextValid(text);
	}
// }

export  default { isTextValid, isBundleIdValid, isBundleRatingValid, isGroupIdValid, isTitleValid, isDescriptionValid, isCodeValid, isSopBodyCountValid, isDataValid, isSopHeaderTitleValid, isSopHeaderContentValid, isSopBodySectionTitleValid, isSopBodyTitleValid, isSopBodyContentValid, isPageDataValid };
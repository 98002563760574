// SOP pages 
export const SOPS = "/sops";
export const SOP_ADD = "/sop/add";
export const SOP_EDIT = "/sop/edit";
export const SOP_PAGE_EDIT = "/sop/page/edit";

// Product pages
export const PRODUCTS = "/products";
export const PRODUCT_ADD = "/product/add";
export const PRODUCT_COMBOS = "/product/combos";

// Home
export const HOME = "/home";
// Login
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const SIGNUP = "/signup";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";

// Success
export const SUCCESS = "/success";

// Coupons
export const COUPONS = "/coupons";

//Customer
export const CUSTOMERS = "/customers";

// Users
export const USERS = "/users";
// Roles
export const ROLES = "/roles";

// Subscribers
export const SUBSCRIBERS = "/subscribers";

// Site-contents 
export const BANNER = "/site-content/banner";
export const RESOURCES_VIDEOS = "/site-content/resources/videos";
export const RESOURCES_ARTICLES = "/site-content/resources/articles";
export const RESOURCES_FAQS = "/site-content/resources/faqs";

export const PAGE401 = "/Page401";

import { Row, Col, Button, Container, Breadcrumb, Form, Table, Alert } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import { URI_ADMIN_COUPONS } from "../../constants";
import axios from "axios";
import CouponDetails from "./CouponDetails";
import moment from "moment";
import { useLoader } from "../../hooks/useLoader";

function ManageCoupons() {
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};
	const [show, setShow] = useState(false);

	const ALERT_CODES = {
		HIDE_ALERT_CODE : -1,
		SUCCESS_CODE : 1,
		FAILURE_CODE : 2
	}
	// const HIDE_ALERT_CODE = -1;
	// const SUCCESS_CODE = 1;
	// const FAILURE_CODE = 2;

	const { startLoader, stopLoader } = useLoader();
	const [showAlert, setShowAlert] = useState(ALERT_CODES.HIDE_ALERT_CODE);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [coupons, setCoupons] = useState([]);
	const couponsResultset = useRef([]);
	const [editCoupon, setEditCoupon] = useState({});
	const deleteCoupon = useRef({});
	const reload = useRef(0);

	useEffect(() => {
		startLoader();
		let pr = populateData();
		Promise.resolve(pr).then(() => stopLoader());
	}, [reload.current]);

	const reloadCoupons = () => { reload.current = (reload.current + 1) % 10 }

	const populateData = async () => {
		return axios.get(URI_ADMIN_COUPONS, config)
      	.then((response) => {
			console.log(response);
			if (response.status === 200) {
				let cpns = response.data;
				setCoupons(cpns);
				couponsResultset.current = cpns;
			}
      	})
		.catch((err) => {
			var error_received = "";
			if (err.response) {
				error_received = err.response.data.detail;
			}
			console.log("error: ", error_received);
		});
	}

	const handleAddCoupon = () => {
		setEditCoupon({});
		setShow(true);
	}
	const handleEditCoupon = (coupon) => {
		setEditCoupon(coupon);
		setShow(true);
	}
	const handleDeleteCoupon = (coupon) => {
		let  couponId = coupon.id
		let promise = axios.delete(URI_ADMIN_COUPONS + "/" + couponId, config);
		deleteCoupon.current = {};
		setShowDeleteConfirmation(false);
		promise.then((response) => {
			console.log(response);
			if (response.status === 200) {
				setShowAlert(ALERT_CODES.SUCCESS_CODE);
				let filteredResults = coupons.filter((c) => c.id !== couponId);
				setCoupons(filteredResults);
				couponsResultset.current = filteredResults;
			} else {
				setShowAlert(ALERT_CODES.FAILURE_CODE);
			}
		}).catch((err) => {console.log(err); setShowAlert(ALERT_CODES.FAILURE_CODE);});
	}

	const handleCouponSearchChange = (text) => {
		let cpns = couponsResultset.current;
		if (text) {
			let subset = cpns.filter((b) => b.code_text.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setCoupons(subset);
		} else {
			setCoupons(cpns);
		}
	};

	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4">
				<div className="innerHead">
					<h1>Manage Coupons</h1>
				</div>
				<div>
					<Alert key="success" variant="success" show={showAlert === ALERT_CODES.SUCCESS_CODE} dismissible onClose={() => setShowAlert(ALERT_CODES.HIDE_ALERT_CODE)} >
						Transaction successful !
					</Alert>
					<Alert key="danger" variant="danger" show={showAlert === ALERT_CODES.FAILURE_CODE} dismissible onClose={() => setShowAlert(ALERT_CODES.HIDE_ALERT_CODE)} >
						Transaction failed !
					</Alert>
				</div>
				<Container fluid="true" className="manageSOP">
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Coupons</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<h4>Coupons List</h4>
						</Col>
						<Col md={4} className="text-end">
							<Button variant="primary" onClick={() => handleAddCoupon()}>
								Add Coupon
							</Button>
						</Col>
					</Row>
					<Row className="formGroup mb-4 mt-3">
						<Col md={6}>
							<Row xs="auto">
								<Col>
									<Form.Control
										type="search"
										placeholder="Search by code"
										className="search-icon me-2"
										aria-label="Search"
										onChange={(e) => handleCouponSearchChange(e.target.value)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped>
								<thead>
									<tr>
										<th>Code</th>
										<th>Valid From</th>
										<th>Valid To</th>
										<th>Allowed Usage</th>
										<th>Discount (%)</th>
										<th>Used</th>
										<th>Utlization (%)</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{coupons.map((c, idx) => {
										return (
											<tr key={idx}>
												<td>{c.code_text}</td>
												<td>{c.valid_from ? moment(new Date(c.valid_from)).format('DD-MM-YYYY') : ""}</td>
												<td>{c.valid_till ? moment(new Date(c.valid_till)).format('DD-MM-YYYY') : ""}</td>
												<td>{c.max_tokens}</td>
												<td>{c.discount}</td>
												<td>{c.utilized_tokens}</td>
												<td>{(c.utilized_tokens/c.max_tokens)*100}%</td>
												<td className="d-flex">
													<Button variant="link" onClick={(e) => {handleEditCoupon(c); e.stopPropagation()}} >
														<i className="bi bi-pencil"></i>
													</Button>{" "}
													<Button variant="link" onClick={(e) => {deleteCoupon.current = c; setShowDeleteConfirmation(true)}}>
														<i className="bi bi-trash"></i>
													</Button>
												</td>
											</tr>
											)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />

			<CouponDetails show={show} setShow={setShow} coupon={editCoupon} setCoupon={setEditCoupon} setShowAlert={setShowAlert} ALERT_CODES={ALERT_CODES} reloadCoupons={reloadCoupons} />
			<Modal show={showDeleteConfirmation} onHide={(e) => setShowDeleteConfirmation(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>Do you really want to delete Coupon "{deleteCoupon.current.code_text}" ? </Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={(e) => setShowDeleteConfirmation(false)}>Cancel</Button>
					<Button variant="primary" onClick={(e) => handleDeleteCoupon(deleteCoupon.current) }>Delete</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ManageCoupons;
import { Row, Col, Button, Container, Breadcrumb, Form, Table, Alert } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import { URI_ADMIN_USERS, URI_ADMIN_ROLE_LIST, URI_ADMIN_USER_ADD, URI_ADMIN_USER } from "../../constants.js";
import axios from "axios";
import { useLoader } from "../../hooks/useLoader";

function Users() {
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const { startLoader, stopLoader } = useLoader();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [showAlert, setShowAlert] = useState(-1);
	const [validated, setValidated] = useState(true);

	const usersResultSet = useRef([]);
	const [users, setUsers] = useState([]);
	const [editUser, setEditUser] = useState({});
	const [roles, setRoles] = useState([]);

	const [orgSortAsc, setOrgSortAsc] = useState(true);

	useEffect(() => {
		populateData();
	}, []);

	const populateData = async () => {
		startLoader();
		let prs = [];
		prs.push(populateUsers());
		prs.push(populateRoles());
		Promise.allSettled(prs).then(() => stopLoader());
	};

	const populateUsers = () => {
		return axios
			.get(URI_ADMIN_USERS, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let usr = response.data;
					// let filtered = usr.filter((u) => u.role_id !== 3 && u.role_id !== 4);
					setUsers(usr);
					usersResultSet.current = usr;
				}
			})
			.catch((err) => {
				var error_received = "";
				if (err.response) {
					error_received = err.response.data.detail;
				}
				console.log("my error: ", error_received);
			});
	};
	const populateRoles = () => {
		return axios
			.get(URI_ADMIN_ROLE_LIST, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let rls = response.data;
					rls = rls.filter((c) => c.active == true);
					setRoles(rls);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleUserSearchTextChange = (text) => {
		let urs = usersResultSet.current;
		if (text) {
			let subset = urs.filter((u) => u.email.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setUsers(subset);
		} else {
			setUsers(urs);
		}
	};

	const handleSaveUserClick = async (event) => {
		event.preventDefault();
		setValidated(false);
		const data = { ...editUser };
		console.log(data);

		if (!validateUser(editUser)) {
			console.log("Missing data. Check all field values.");
			return;
		}

		startLoader();
		if (editUser.id && editUser.id > 0) {
			axios
				.put(URI_ADMIN_USER + "/" + editUser.id, data, config)
				.then((response) => {
					if (response.status === 200) {
						// let updatedUser = response.data;
						setUsers(users.map((u) => (u.id === editUser.id ? data : u)));
						setShowAlert(1);
					} else {
						setShowAlert(2);
					}
				})
				.catch((error) => {
					console.log(error);
					setShowAlert(2);
				})
				.finally(() => {
					setShow(false);
					stopLoader();
				});
		} else {
			axios
				.post(URI_ADMIN_USER_ADD, data, config)
				.then((response) => {
					if (response.status === 200) {
						let newUser = response.data;
						console.log(newUser);
						setUsers([...users, newUser]);
						let allUsers = usersResultSet.current;
						usersResultSet.current = [...allUsers, newUser];
						// populateData();
						// populateUsers();
						setShowAlert(1);
					} else {
						setShowAlert(2);
					}
				})
				.catch((error) => {
					console.log(error);
					setShowAlert(2);
				})
				.finally(() => {
					setShow(false);
					stopLoader();
				});
		}
	};

	const validateUser = (user) => {
		return (
			isValidText(user.firstname) &&
			isValidText(user.lastname) &&
			isValidText(user.phone) &&
			isValidText(user.email) &&
			user.role_id > 0 &&
			isValidPassword(user)
		);
	};

	const isValidText = (text) => {
		return text && text.trim() !== "";
	};

	const isValidPassword = (user) => {
		return user.id && user.id > 0 ? true : isValidText(user.password);
	};

	const handleAddUserClicked = () => {
		setEditUser({});
		setValidated(true);
		setShow(true);
	};

	const handleEditUserClicked = (index) => {
		if (index > -1 && index < users.length) {
			let user = users[index];
			setEditUser(user);
			setValidated(true);
			setShow(true);
		}
	};

	const handleDeleteUserClicked = (index) => {
		if (index > -1 && index < users.length) {
			let user = users[index];
			startLoader();
			axios
				.delete(URI_ADMIN_USER + "/" + user.id, config)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						setShowAlert(1);
						setUsers(users.filter((u) => u.id !== user.id));
					} else {
						setShowAlert(2);
					}
				})
				.catch((error) => {
					console.log(error);
					setShowAlert(2);
				})
				.finally(() => stopLoader());
		}
	};

	const handleUserFilterRoleChange = (roleId) => {
		let urs = usersResultSet.current;
		if (roleId) {
			let subset = urs.filter((u) => u.role === roleId);
			setUsers(subset);
		} else {
			setUsers(urs);
		}
	};

	const handleUserOrgSortChange = () => {
		console.log("sort order : " + orgSortAsc);
		let usr = usersResultSet.current;
		let sorted;
		if (orgSortAsc) {
			sorted = usr.sort((u1, u2) => (u1.company_name <= u2.company_name ? 1 : -1));
		} else {
			sorted = usr.sort((u1, u2) => (u1.company_name > u2.company_name ? 1 : -1));
		}
		setUsers(sorted);
		setOrgSortAsc(!orgSortAsc);
	};

	return (
		<>
			<Navbar />
			<div className='g-0 home-top-padding mb-4'>
				<div>
					<Alert key='success' variant='success' show={showAlert === 1} dismissible onClose={() => setShowAlert(-1)}>
						Transaction successful !
					</Alert>
					<Alert key='danger' variant='danger' show={showAlert === 2} dismissible onClose={() => setShowAlert(-1)}>
						Transaction failed !
					</Alert>
				</div>
				<div className='innerHead'>
					<h1>Manage Users</h1>
				</div>
				<Container fluid='true' className='manageSOP'>
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href='/Home'>Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Users</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<h4>Users List</h4>
						</Col>
						<Col md={4} className='text-end'>
							{/* <Button variant="outline-primary" className="me-2">
								Delete
							</Button> */}
							<Button variant='primary' onClick={(e) => handleAddUserClicked()}>
								Add User
							</Button>
						</Col>
					</Row>
					<Row className='formGroup mb-4 mt-3'>
						<Col md={6}>
							<Row xs='auto'>
								<Col>
									<Form.Control
										type='search'
										placeholder='Search by username'
										className='search-icon me-2'
										aria-label='Search'
										onChange={(e) => handleUserSearchTextChange(e.target.value)}
									/>
								</Col>
							</Row>
						</Col>
						<Col className='text-end'>
							<Row xs='auto' className='float-right'>
								<Col className='mt-2 g-0'>Filter by role</Col>
								<Col>
									<Form.Select aria-label='Role' onChange={(e) => handleUserFilterRoleChange(e.target.value)}>
										<option key='0' value=''></option>
										{roles.map((r, idx) => {
											return (
												<option key={idx} value={r.title}>
													{r.title}
												</option>
											);
										})}
									</Form.Select>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped>
								<thead>
									<tr>
										<th>#</th>
										<th>First Name</th>
										<th>Last Name</th>
										<th>Username</th>
										<th>
											Organisation
											<Button variant='border' onClick={() => handleUserOrgSortChange()}>
												{orgSortAsc ? <code>&#65514;</code> : <code>&#65516;</code>}
											</Button>
										</th>
										<th>Role</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{users.map((u, idx) => {
										return (
											<tr key={idx}>
												<td>{idx + 1}</td>
												<td>{u.firstname}</td>
												<td>{u.lastname}</td>
												<td>{u.email}</td>
												<td>{u.company_name}</td>
												<td>{u.role ? u.role : ""}</td>
												<td>
													{u.role &&
													u.role.toLowerCase() !== "customer-admin" &&
													u.role.toLowerCase() !== "super-admin" &&
													u.role.toLowerCase() !== "customer-user" ? (
														<>
															<Button variant='link' onClick={(e) => handleEditUserClicked(idx)}>
																<i className='bi bi-pencil'></i>
															</Button>
															<Button variant='link' onClick={(e) => handleDeleteUserClicked(idx)}>
																<i className='bi bi-trash'></i>
															</Button>
														</>
													) : (
														""
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />

			<Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Add User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form noValidate validated={validated}>
						<Row>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='firstname'>
									<Form.Label className='d-none'>First Name</Form.Label>
									<Form.Control
										type='text'
										placeholder='First Name'
										isInvalid={!isValidText(editUser.firstname)}
										value={editUser.firstname ? editUser.firstname : ""}
										onChange={(e) => setEditUser({ ...editUser, firstname: e.target.value })}
									/>
									{!validated ? (
										<Form.Control.Feedback type='invalid'>Please provide firstname.</Form.Control.Feedback>
									) : (
										""
									)}
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='lastname'>
									<Form.Label className='d-none'>Last Name</Form.Label>
									<Form.Control
										type='text'
										placeholder='Last Name'
										isInvalid={!isValidText(editUser.lastname)}
										value={editUser.lastname ? editUser.lastname : ""}
										onChange={(e) => setEditUser({ ...editUser, lastname: e.target.value })}
									/>
									{!validated ? (
										<Form.Control.Feedback type='invalid'>Please provide lastname.</Form.Control.Feedback>
									) : (
										""
									)}
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='password'>
									<Form.Label className='d-none'>Password</Form.Label>
									<Form.Control
										type='password'
										disabled={editUser.id && editUser.id > 0 ? true : false}
										isInvalid={!isValidPassword(editUser)}
										placeholder='Password'
										autoComplete='off'
										value={editUser.password ? editUser.password : ""}
										onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
									/>
									{!validated ? (
										<Form.Control.Feedback type='invalid'>Please provide password.</Form.Control.Feedback>
									) : (
										""
									)}
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='mobnumber'>
									<Form.Label className='d-none'>Mobile Number</Form.Label>
									<Form.Control
										type='text'
										placeholder='Mobile Number'
										isInvalid={!isValidText(editUser.phone)}
										value={editUser.phone ? editUser.phone : ""}
										onChange={(e) => setEditUser({ ...editUser, phone: e.target.value })}
									/>
									{!validated ? (
										<Form.Control.Feedback type='invalid'>Please provide Mobile number.</Form.Control.Feedback>
									) : (
										""
									)}
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='email'>
									<Form.Label className='d-none'>E-mail</Form.Label>
									<Form.Control
										type='email'
										disabled={editUser.id && editUser.id > 0 ? true : false}
										placeholder='E-mail'
										isInvalid={!isValidText(editUser.email)}
										value={editUser.email ? editUser.email : ""}
										onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
									/>
									{!validated ? (
										<Form.Control.Feedback type='invalid'>Please email cannot be empty.</Form.Control.Feedback>
									) : (
										""
									)}
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='role'>
									<Form.Label className='d-none'>Role</Form.Label>
									<Form.Select
										aria-label='role'
										isInvalid={editUser.role_id && editUser.role_id > 0 ? false : true}
										value={editUser.role_id ? editUser.role_id : 0}
										onChange={(e) => setEditUser({ ...editUser, role_id: e.target.value })}
									>
										<option key={0} value={0}>
											Select a Role
										</option>
										{roles.map((r, idx) => {
											return (
												<option key={idx + 1} value={r.id}>
													{r.title}
												</option>
											);
										})}
									</Form.Select>
									{!validated ? <Form.Control.Feedback type='invalid'>Please select Role.</Form.Control.Feedback> : ""}
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='outline-primary' onClick={handleClose}>
						Close
					</Button>
					<Button variant='primary' onClick={(e) => handleSaveUserClick(e)}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Users;

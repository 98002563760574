import { lazy, startTransition, Suspense, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import SuccessPage from "./pages/SuccessPage";
import ManageSOP from "./pages/sop/ManageSOP";
import ManageProducts from "./pages/products/ManageProducts";
import ProductDetails from "./pages/products/ProductDetails";
import Users from "./pages/users/Users";
import Customers from "./pages/customers/Customers";
import ManageCoupons from "./pages/coupons/ManageCoupons";
import LoginSummary from "./pages/reports/LoginSummary";
import OrganizationReport from "./pages/reports/OrganizationReport";
import SOPBundleReport from "./pages/reports/SOPBundleReport";
import SOPCountPerOrg from "./pages/reports/SOPCountPerOrg";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import SignUp from "./pages/sign-up/SignUp";
import "./App.css";
import {
	HOME,
	SOPS,
	SOP_ADD,
	PRODUCTS,
	PRODUCT_COMBOS,
	LOGIN,
	SIGNUP,
	FORGOT_PASSWORD,
	TERMS_AND_CONDITIONS,
	PRIVACY_POLICY,
	SOP_EDIT,
	SUCCESS,
	SOP_PAGE_EDIT,
	CUSTOMERS,
	COUPONS,
	USERS,
	SUBSCRIBERS,
	RESOURCES_ARTICLES,
	RESOURCES_VIDEOS,
	RESOURCES_FAQS,
	ROLES,
	PAGE401
} from "./pageLocations";
import MySOP from "./pages/sop/MySOP";
import ManageSubscribers from "./pages/subscriptions/ManageSubscribers";
import Resources from "./pages/resources/Resources";
import { LoaderContext } from "./context/LoaderContext";
import Roles from "./pages/roles/Roles";
import axios from "axios";
import Page401 from "./pages/Page401";

const Login = lazy(() => import("./pages/Login"));
const SOPDetails = lazy(() => import("./pages/sop/SOPDetails"));

function App() {

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	axios.interceptors.response.use(
		response => response,
		error => {
			const status = error.response ? error.response.status : null;
			console.log(error);
			if (status && status === 401) {
				startTransition(() => {
					navigate(PAGE401);
				});
			}
			return Promise.reject(error);
		}
	);

	return (
		<div className="App">
			{loading && (
				<div className='loading'>
					<div id='loading-wrapper'>
						<div id='loading-text'></div>
						<div id='loading-content'></div>
					</div>
				</div>
			)}

			<LoaderContext.Provider value={{ loading, setLoading }}>
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route path="/" element={<Login />}></Route>
						<Route path={HOME} element={<Home />}></Route>
						<Route path={FORGOT_PASSWORD} element={<ForgotPassword />}></Route>
						<Route path={SIGNUP} element={<SignUp />}></Route>
						<Route path={TERMS_AND_CONDITIONS}></Route>
						<Route path={PRIVACY_POLICY}></Route>
						<Route path={LOGIN} element={<Login />}></Route>
						<Route path={SOP_ADD} element={<SOPDetails />}></Route>
						<Route path={SOP_EDIT} element={<SOPDetails />}></Route>
						<Route path={SOP_PAGE_EDIT} element={<MySOP />}></Route>
						<Route path={SOPS} element={<ManageSOP />}></Route>
						<Route path={PRODUCTS} element={<ManageProducts />}></Route>
						<Route path={PRODUCT_COMBOS} element={<ProductDetails />}></Route>
						<Route path={USERS} element={<Users />}></Route>
						<Route path={ROLES} element={<Roles />}></Route>
						<Route path={CUSTOMERS} element={<Customers />}></Route>
						<Route path={COUPONS} element={<ManageCoupons />}></Route>
						<Route path={SUBSCRIBERS} element={<ManageSubscribers />} />
						<Route path={RESOURCES_ARTICLES} element={<Resources />} />
						<Route path={RESOURCES_VIDEOS} element={<Resources />} />
						<Route path={RESOURCES_FAQS} element={<Resources />} />

						<Route path="/reports/login-summary" element={<LoginSummary />}></Route>
						<Route path="/reports/org-reports" element={<OrganizationReport />}></Route>
						<Route path="/reports/sop-bundle-reports" element={<SOPBundleReport />}></Route>
						<Route path="/reports/sop-count-per-org" element={<SOPCountPerOrg />}></Route>
						<Route path={SUCCESS} element={<SuccessPage />}></Route>
						<Route path={PAGE401} element={<Page401 />} />
						<Route path="/*" element={<Page404 />}></Route>
					</Routes>
				</Suspense>
			</LoaderContext.Provider>
			{/* <Suspense fallback={<div>Loading...</div>}>
		<Outlet />
	  </Suspense> */}
		</div>
	);
}
export default App;

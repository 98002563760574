
const SopReducer = (state, action) => {
	switch(action.type) {
		case 'editSop': {
			return action.sop;
	  	}
		case 'modifySopId' : {
			return {...state, id: action.sop.id};
		}
		case 'modifyBundleId': {
			return {...state, sop_bundle_id: action.sop.sop_bundle_id};
		}
		case 'modifyGroupId' : {
			return {...state, sop_group_id: action.sop.sop_group_id };
		}
		case 'modifySopTitle': {
			return {...state, title: action.sop.title};
		}
		case 'modifySopDescription': {
			return {...state, description: action.sop.description} ;
		}
		case 'modifyBodySectionTitle': {
			return {...state, body_section_title: action.sop.body_section_title};
		}
		case 'modifyHeaderTitle': {
			let index = action.sop.headerIndex;
			let pg = {...state.page};
			pg.sop_header_blocks[index].title = action.sop.title;
			return {...state, page: pg} ;
		}
		case 'modifyHeaderContent': {
			let index = action.sop.headerIndex;
			let pg = {...state.page};
			pg.sop_header_blocks[index].text_content = action.sop.text_content;
			return {...state, page: pg};
		}
		case 'modifyBodyTitle': {
			let index = action.sop.bodyIndex;
			let pg = {...state.page};
			pg.sop_body_blocks[index].title = action.sop.title;
			return {...state, page: pg};
		}
		case 'modifyBodyContent': {
			let index = action.sop.bodyIndex;
			let pg = {...state.page};
			pg.sop_body_blocks[index].text_content = action.sop.text_content;
			return {...state, page: pg};
		}
		case 'modifyRemarks': {
			let pg = {...state.page};
			pg.remarks = action.sop.remarks;
			return {...state, page: pg};
		}
		case 'modifyUpdateSummary': {
			let pg = {...state.page};
			pg.update_summary = action.sop.update_summary;
			return {...state, page: pg};
		}
		default : {
			console.log("error");
			return state;
		}
	}
}

export default SopReducer;
import { createContext, startTransition, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../pageLocations";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	
	// const navigate = useNavigate();

	const [auth, setAuth] = useState({
		isAuthenticated: false,
		userData: {}
	});

	useEffect (() => {
		const token = localStorage.getItem("token");
		if (token) {
			try {
				const tokenObj = JSON.parse(token);
				const userData = jwtDecode(tokenObj.id_token)
				setAuth({
					isAuthenticated: true,
					userData
				})
			}
			catch (error) {
				console.log(error);
			}
		} else {
			// startTransition(() => {navigate(LOGIN)});
		}
	}, []);

	return (
		<AuthContext.Provider value={{ auth, setAuth }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;

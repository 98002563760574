import { Row, Col, Container, Breadcrumb, Form, Table } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import axios from "axios";
import { URI_ADMIN_CUSTOMERS } from "../../constants";
import moment from "moment";
import { useLoader } from "../../hooks/useLoader";

function Customers() {
	const config = useRef({});

	const [customers, setCustomers] = useState([]);
	const customersResultSet = useRef([]);
	const [custOrgs, setCustOrgs] = useState([]);

	const { startLoader, stopLoader } = useLoader();

	useEffect(() => {
		const authToken = localStorage.getItem("token");
		let idToken;
		if (authToken) {
			const { id_token } = JSON.parse(authToken);
			idToken = id_token;
		}
		config.current = {
			headers: {
				Authorization: "Bearer " + idToken,
			},
		};
		populateData();
	}, []);

	const populateData = async () => {
		startLoader();
		let pr = populateCustomers();
		Promise.resolve(pr).then(() => stopLoader());
	}

	const populateCustomers = () => {
		return axios.get(URI_ADMIN_CUSTOMERS, config.current)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let custs = response.data;
					custs = custs.sort((a, b) => a.email > b.email ? 1 : -1);
					setCustomers(custs);
					customersResultSet.current = custs;
					populateCustomerOrgs(custs);
				}
			})
			.catch((err) => {
				var error_received = "";
				if (err.response) {
					error_received = err.response.data.detail;
				}
				console.log("my error: ", error_received);
			})
	}

	const populateCustomerOrgs = (customers) => {
		let orgs = {}
		customers.map((org) => orgs[org.company_name] = "true");
		let orgKeys = Object.keys(orgs);
		setCustOrgs(orgKeys);
	}

	const handleCustomerSearchTextChange = (text) => {
		let cst = customersResultSet.current;
		if (text) {
			let subset = cst.filter((c) => c.email.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setCustomers(subset);
		} else {
			setCustomers(cst);
		}
	}

	const handleFilterByOrganisationChange = (text) => {
		let cst = customersResultSet.current;
		if (text) {
			let subset = cst.filter((c) => c.company_name === text);
			setCustomers(subset);
		} else {
			setCustomers(cst);
		}
	}

	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4">
				<div className="innerHead">
					<h1>Manage Customers</h1>
				</div>
				<Container fluid="true" className="manageSOP">
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Customers</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<h4>Customers List</h4>
						</Col>
					</Row>
					<Row className="formGroup mb-4 mt-3">
						<Col md={6}>
							<Row xs="auto">
								<Col>
									<Form.Control
										type="search"
										placeholder="Search by ID"
										className="search-icon me-2"
										aria-label="Search"
										onChange={(e) => handleCustomerSearchTextChange(e.target.value)}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="text-end">
							<Row xs="auto" className="float-right">
								<Col className="mt-2 g-0">Filter by Orginazation</Col>
								<Col>
									<Form.Select aria-label="status" onChange={(e) => handleFilterByOrganisationChange(e.target.value)}>
										<option key="-1" value=""></option>
										{custOrgs.map((o, idx) => { return (<option key={idx} value={o}>{o}</option>) })}
									</Form.Select>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped>
								<thead>
									<tr>
										<th>ID</th>
										<th>First Name</th>
										<th>Last Name</th>
										<th>Orginazation</th>
										<th>Effective Revenue</th>
										<th>Subscription Date</th>
									</tr>
								</thead>
								<tbody>
									{customers.map((c, idx) => {
										return (
											<tr>
												<td>{customers[idx-1] && c.email === customers[idx-1].email ? "" : c.email }</td>
												<td>{customers[idx-1] && c.first_name === customers[idx-1].first_name ? "" : c.first_name }</td>
												<td>{customers[idx-1] && c.last_name === customers[idx-1].last_name ? "" : c.last_name }</td>
												<td>{customers[idx-1] && c.company_name === customers[idx-1].company_name ? "" : c.company_name }</td>
												<td>{c.effective_price}</td>
												<td>{c.created_at ? moment(new Date(c.created_at)).format('DD-MM-YYYY') : ""}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</>
	);
}

export default Customers;

import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ManageVideo = ({dispatch}) => {

	const [video, setVideo] = useState({});
	const handleCancel = () => {
		dispatch({ type: 'cancelVideo' });
	}
	return (
		<Modal show={true} onHide={() => handleCancel()} animation={true}>
			<Modal.Header closeButton>
				<Modal.Title>Add Video</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="firstname">
								<Form.Label className="d-none">Video ID</Form.Label>
								<Form.Control type="text" placeholder="Video ID" value={video.video_id ? video.video_id : ""} onChange={(e) => setVideo({...video, video_id: e.target.value})} />
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-primary" onClick={() =>handleCancel()}>
					Close
				</Button>
				<Button variant="primary" onClick={(e) => dispatch({type: "addVideo", video: video })}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ManageVideo;
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ManageArticle = ({dispatch}) => {

	const [article, setArticle] = useState({});
	const handleCancel = () => {
		dispatch({ type: 'cancelArticle' });
	}
	return (
		<Modal show={true} onHide={() => handleCancel()} animation={true}>
			<Modal.Header closeButton>
				<Modal.Title>Add Article</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="firstname">
								<Form.Label className="d-none">Title</Form.Label>
								<Form.Control type="text" placeholder="Title" value={article.title} onChange={(e) => setArticle({...article, title: e.target.value})} />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="usages">
								<Form.Label className="d-none">Article</Form.Label>
								<Form.Control as="textarea" style={{ height: "200px" }} placeholder="Article" value={article.article_text} onChange={(e) => {setArticle({...article, article_text: e.target.value})}} />
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-primary" onClick={() =>handleCancel()}>
					Close
				</Button>
				<Button variant="primary" onClick={(e) => dispatch({type: "addArticle", article: article })}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ManageArticle;
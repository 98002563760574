import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container, Button, Card, Form } from "react-bootstrap";
import { ImgLogo } from "./../../components/Import";
// import Policies from "./../../components/Policies";
import { URL_GET_IP_ADDRESS, URL_SIGNUP } from "./../../constants";
import axios from "axios";

export const SignUp = () => {
	const navigate = useNavigate();
	const firstnameRef = useRef();
	const lastnameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const organizationRef = useRef();
	const [ipAddress, setIpAddress] = useState();

	const onSubmitForm = async (event) => {
		const form = event.currentTarget;
		event.preventDefault();

		var data = {
			firstname: firstnameRef.current.value,
			lastname: lastnameRef.current.value,
			email: emailRef.current.value,
			password: passwordRef.current.value,
			phone: phoneRef.current.value,
			org: organizationRef.current.value,
			last_login_ip_address: ipAddress,
		};

		navigate("/confirm-signup", { state: { id: 1, username: emailRef.current.value } });
		return;

		axios
			.post(URL_SIGNUP, data)
			.then((response) => {
				navigate("/confirm-signup", { state: { id: 1, username: emailRef.current.value } });
			})
			.catch((error) => {
				var error_received = "Invalid username or password.";
				if (error.response) {
					error_received = error.response.data.detail;
				}
				//setErrorMessage(error_received);
				//setShowAlert(true);
			});
	};

	useEffect(() => {
		fetch(URL_GET_IP_ADDRESS)
			.then((res) => res.text())
			.then((data) => {
				console.log(data);
				setIpAddress(data);
			});
	});

	return (
		<Container fluid='true' className='login-bg position-relative'>
			<Card className='login'>
				<div className='position-absolute back-btn'>
					<a href='/'>
						<i className='bi bi-arrow-left-short'></i>
					</a>
				</div>
				<div className='login-logo'>
					<img className='d-block' src={ImgLogo} alt='mySOP guide' />
				</div>
				<h4 className='text-center mb-3'>Create account</h4>
				<Form onSubmit={onSubmitForm}>
					<Row className='mb-3'>
						<Col>
							<Form.Floating>
								<Form.Control id='txtFirstname' type='text' placeholder='Firstname' ref={firstnameRef} required />
								<label htmlFor='txtFirstname'>Firstname</label>
							</Form.Floating>
						</Col>
						<Col>
							<Form.Floating>
								<Form.Control id='txtLastname' type='text' placeholder='Lastname' ref={lastnameRef} required />
								<label htmlFor='txtLastname'>Lastname</label>
							</Form.Floating>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Floating className='mb-3'>
								<Form.Control id='txtEmail' type='email' placeholder='name@example.com' ref={emailRef} required />
								<label htmlFor='txtEmail'>Email Address</label>
							</Form.Floating>
						</Col>
					</Row>
					<Form.Floating className='mb-3'>
						<Form.Control id='txtPassword' type='password' placeholder='Password' required ref={passwordRef} />
						<label htmlFor='txtPassword'>Password</label>
					</Form.Floating>
					<Form.Floating className='mb-3'>
						<Form.Control
							id='txtConfirmPassword'
							type='password'
							placeholder='Password'
							required
							ref={confirmPasswordRef}
						/>
						<label htmlFor='txtConfirmPassword'>Confirm password</label>
					</Form.Floating>
					<Form.Floating className='mb-3'>
						<Form.Control id='txtOrganisation' type='text' placeholder='Organisation' required ref={organizationRef} />
						<label htmlFor='txtOrganisation'>Company (Optional)</label>
					</Form.Floating>
					<Form.Floating className='mb-2'>
						<Form.Control
							id='txtContact'
							type='text'
							placeholder='Contact number'
							className='is-invalid'
							ref={phoneRef}
						/>
						<label htmlFor='txtMobile'>Contact number (Optional)</label>
					</Form.Floating>
					{/* <Policies /> */}
					<Button variant='primary' className='w-100' type='submit'>
						Continue
					</Button>
				</Form>
			</Card>
		</Container>
	);
};
export default SignUp;

import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ManageFeed = ({socialFeed, dispatch}) => {

	const [feed, setFeed] = useState(socialFeed);
	const handleCancel = () => {
		dispatch({ type: 'cancelFeed' });
	}
	return (
		<Modal show={true} onHide={() => handleCancel()} animation={true}>
			<Modal.Header closeButton>
				<Modal.Title>Update Feed</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="firstname">
								<Form.Label className="d-none">Social Media</Form.Label>
								<Form.Control type="text" placeholder="Social Media" value={feed.social_media} onChange={(e) => setFeed({...feed, social_media: e.target.value})} />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="usages">
								<Form.Label className="d-none">Plugin Code</Form.Label>
								<Form.Control as="textarea" style={{ height: "200px" }} placeholder="Plugin Code" value={feed.plugin_code} onChange={(e) => {setFeed({...feed, plugin_code: e.target.value})}} />
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-primary" onClick={() =>handleCancel()}>
					Close
				</Button>
				<Button variant="primary" onClick={(e) => dispatch({type: "updateFeed", feed: feed })}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ManageFeed;
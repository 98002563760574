import { useContext } from "react";
import { LoaderContext } from "../context/LoaderContext";

export const useLoader = () => {
    const { loading, setLoading } = useContext(LoaderContext);

    const startLoader = () => {
        setLoading(true);
    }
    const stopLoader = () => {
        setLoading(false);
    }

    return { loading, startLoader, stopLoader }
}

import { Row, Col, Button, Container, Breadcrumb, Form, Table, Alert } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import { PRODUCTS } from "../../pageLocations";
import axios from "axios";
import { URI_ADMIN_PRODUCT } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../hooks/useLoader";

function ProductDetails() {
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const navigate = useNavigate();
	const [showAlert, setShowAlert] = useState(-1);
	const { startLoader, stopLoader } = useLoader();
	// const [singleMode, setSingleMode] = useState(false);
	const emptyProductCombo = {
		id: 0,
		title: "",
		list_price: 0,
		effective_price: 0,
		discount_percentage: 0,
		product_type: "COMBO",
		product_items: [],
		updated_by: 1,
		description: ""
	};
	const [product, setProduct] = useState(emptyProductCombo);
	const masterProducts = useRef([]);

	useEffect(() => {
		populateData();
	}, []);

	const populateData = () => {
		// let mp =
		masterProducts.current = JSON.parse(localStorage.getItem("masterProducts"));

		let pc = localStorage.getItem("productContext");
		let newpc = pc ? JSON.parse(pc) : { ...emptyProductCombo };
		newpc = newpc.id > 0 ? newpc : { ...emptyProductCombo };

		if (newpc.product_type && newpc.product_type.toUpperCase() === "COMBO") {
			let newItms = newpc.product_items.map((pi) => {
				let p = masterProducts.current.filter((cp) => cp.product_items[0].sop_bundle_id === pi.sop_bundle_id)[0];
				return {
					...pi,
					title: p.title,
					list_price: p.list_price,
					discount_percentage: p.discount_percentage,
					effective_price: p.effective_price,
				};
			});
			newpc = { ...newpc, product_items: newItms };
		}
		if (!newpc.discount_percentage) {
			newpc.discount_percentage = 0;
		}
		setProduct(newpc);
	};

	const handleMasterProductSelectionChange = (index) => {
		let mp = masterProducts.current[index];
		let itms = {
			title: mp.title,
			sop_bundle_id: mp.product_items[0].sop_bundle_id,
			list_price: mp.list_price,
			discount_percentage: mp.discount_percentage,
			effective_price: mp.effective_price,
		};
		let newItms = [...product.product_items, itms];
		let totalEffectivePrice = newItms.reduce((sum, itm) => {
			return sum + itm.effective_price;
		}, 0);
		let totalListPrice = newItms.reduce((sum, itm) => {
			return sum + itm.list_price;
		}, 0);
		setProduct({
			...product,
			list_price: totalListPrice,
			discount_percentage: 0,
			effective_price: totalEffectivePrice,
			product_items: newItms,
		});
	};

	const handleMasterProductDeleteClick = (index) => {
		let subSetItms = product.product_items.filter((p, idx) => idx !== index);
		setProduct({ ...product, product_items: subSetItms });
	};

	const handleAdditionalDiscountChange = (discountPercent) => {
		let disc = discountPercent ? discountPercent : 0;
		disc = disc < 0 ? disc * -1 : disc;
		disc = disc > 100 ? 100 : disc;
		let itemsEffectivePrice =
			product.product_type.toUpperCase() === "COMBO"
				? product.product_items.reduce((sum, itm) => {
					return sum + itm.effective_price;
				}, 0)
				: product.list_price;
		let discount = (itemsEffectivePrice * disc) / 100;
		let effectivePrice = Math.round(itemsEffectivePrice - discount);
		setProduct({ ...product, discount_percentage: disc, effective_price: effectivePrice });
	};

	const handleListPriceChange = (listPrice) => {
		let discount = (listPrice * product.discount_percentage) / 100;
		let effectivePrice = Math.round(listPrice - discount);
		setProduct({ ...product, list_price: listPrice, effective_price: effectivePrice });
	};

	const handleComboNameChange = (text) => {
		setProduct({ ...product, title: text });
	};
	const handleComboDescChange = (text) => {
		setProduct({ ...product, description: text });
	};

	const handleComboSaveClicked = () => {
		
		if (isDataValid()) {
			startLoader();
			let promise = product.id > 0 ? updateProductCombo() : insertProductCombo();
			promise
				.then((response) => {
					// navigate(PRODUCTS);
					setShowAlert(0);
				})
				.catch((err) => {
					setShowAlert(1);
				}).finally(() => stopLoader());
		} else {
			setShowAlert(2);
		}
	};

	const handleCancelClicked = () => {
		navigate(PRODUCTS);
	};

	const isDataValid = () => {
		if (product.product_type === "COMBO") {
			return isTextValid(product.title) && product.product_items.length > 1;
		} else {
			return isTextValid(product.title) && product.list_price && product.list_price >= 0;
		}
	};
	const insertProductCombo = () => {
		let payload = { ...product };
		if (!payload.discount_percentage) {
			payload.discount_percentage = 0;
		}
		if (!payload.list_price) {
			payload.list_price = 0;
		}
		if (!payload.effective_price) {
			payload.effective_price = 0;
		}
		if (!payload.product_type) {
			payload.product_type = payload.product_items.length > 1 ? "COMBO" : "SINGLE";
		}
		return axios.post(URI_ADMIN_PRODUCT, { ...product }, config);
	};

	const updateProductCombo = () => {
		return axios.put(URI_ADMIN_PRODUCT + product.id, { ...product }, config);
	};

	const isTextValid = (text) => {
		return text && text.trim() !== "";
	};

	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4">
				<div>
					<Alert
						key="success"
						variant="success"
						show={showAlert === 0}
						dismissible
						onClose={() => setShowAlert(-1)}
					>
						Data saved successfully!
					</Alert>
					<Alert key="danger" variant="danger" show={showAlert === 1} dismissible onClose={() => setShowAlert(-1)}>
						Data saved failed!
					</Alert>
					<Alert
						key="warning"
						variant="warning"
						show={showAlert === 2}
						dismissible
						onClose={() => setShowAlert(-1)}
					>
						Invalid Data! Please check combo name, list price or atleast two product masters are selected.
					</Alert>
				</div>
				<div className="innerHead">
					<h1>Add Combo</h1>
				</div>
				<Container fluid="true" className="manageSOP">
					<Form noValidate validated={false}>
						<Row>
							<Col>
								<Breadcrumb>
									<Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href={PRODUCTS}>Products</Breadcrumb.Item>
									<Breadcrumb.Item active>Add Combo</Breadcrumb.Item>
								</Breadcrumb>
							</Col>
						</Row>
						
						<Row>
							<Col md={8}>
								<h4>{product.product_type === "COMBO" ? "Combo" : "Product"} Details</h4>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="comboName">
									<Form.Label className="d-none">Combo Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Combo Name"
										maxLength="50"
										required
										isInvalid={!isTextValid(product.title)}
										value={product.title}
										onChange={(e) => handleComboNameChange(e.target.value)}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="comboDescr">
									<Form.Label className="d-none">Description</Form.Label>
									<Form.Control
										type="text"
										placeholder=" Description"
										maxLength="2000"
										required
										isInvalid={!isTextValid(product.description)}
										value={product.description}
										onChange={(e) => handleComboDescChange(e.target.value)}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							{product.product_type === "COMBO" ? (
								<Col md={4}>
									<Form.Group className="mb-3" controlId="workSelect">
										<Form.Select
											aria-label="Default select example"
											value={-1}
											onChange={(e) => handleMasterProductSelectionChange(e.target.value)}
										>
											<option key={-1} value={-1}>
												Select Product Masters
											</option>
											{masterProducts.current
												? masterProducts.current.map((p, idx) => {
													return (
														<option key={idx} value={idx}>
															{p.title}
														</option>
													);
												})
												: ""}
										</Form.Select>
									</Form.Group>
								</Col>
							) : null}
							{/* <Col md={1}>
								<Form.Check type="switch" id="combo-switch" />
							</Col> */}
						</Row>
						<Row>
							<Col md={12}>
								{product.product_type === "COMBO" ? (<><hr></hr><h4>Combo Product</h4></>) : ""}
							</Col>
							<Col>
								<Table striped>
									<thead>
										<tr>
											<th>{product.product_type === "COMBO" ? "Combo" : "Product"} Items</th>
											<th>List Price</th>
											<th>Discount (%)</th>
											<th>Effective Price</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody isInvalid={!isDataValid()}>
										{product &&
											product.product_type &&
											"COMBO" === product.product_type.toUpperCase() &&
											product.product_items
											? product.product_items.map((pi, idx) => {
												return (
													<tr key={idx}>
														<td>{pi.title}</td>
														<td>{pi.list_price}</td>
														<td>{pi.discount_percentage}</td>
														<td>{pi.effective_price}</td>
														<td className="d-flex">
															<Button variant="link" onClick={(e) => handleMasterProductDeleteClick(idx)}>
																<i className="bi bi-trash"></i>
															</Button>
														</td>
													</tr>
												);
											})
											: null}
										<tr>
											<td colSpan={1}>Additional Discount</td>
											<td>
												<Form.Control
													size="sm"
													type="number"
													placeholder="List Price"
													value={product.list_price}
													onChange={(e) => handleListPriceChange(e.target.value)}
												/>
											</td>
											<td>
												<Form.Control
													size="sm"
													type="number"
													placeholder="Discount"
													value={product.discount_percentage}
													onChange={(e) => handleAdditionalDiscountChange(e.target.value)}
												/>
											</td>
											<td>{product.effective_price}</td>
											<td></td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button variant="outline-primary" className="me-2" onClick={(e) => handleCancelClicked()}>
									Cancel
								</Button>
								<Button variant="primary" onClick={(e) => handleComboSaveClicked()}>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
			<Footer />
		</>
	);
}

export default ProductDetails;

import React, { useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { URL_INITIATE_FORGOT_PASSWORD } from "./../../constants";
import axios from "axios";
import Policies from "./../../components/Policies";

function GenerateCode({ showStep2, setMessage }) {
	const emailRef = useRef();

	const onSubmitForm = async (event) => {
		event.preventDefault();
		showStep2(emailRef.current.value);
		/*
		var data = {
			username: emailRef.current.value,
		};

		axios
			.post(URL_INITIATE_FORGOT_PASSWORD, data)
			.then((response) => {
				setUsername(emailRef.current.value);
			})
			.catch((error) => {
				var error_received = "Invalid username or password.";
				if (error.response) {
					error_received = error.response.data.detail;
				}
				setMessage('danger',error_received);				
			});
        */
	};

	return (
		<>
			<h4 className='mb-3'>Password assistance</h4>
			<p>Enter the email address associated with your mySOPGuide account.</p>
			<Form onSubmit={onSubmitForm}>
				<Form.Floating className='mb-3'>
					<Form.Control
						id='floatingInputCustom'
						type='email'
						placeholder='name@example.com'
						required
						maxLength={50}
						ref={emailRef}
					/>
					<label htmlFor='floatingInputCustom'>Email Address</label>
				</Form.Floating>
				<Policies />
				<Button variant='primary' className='w-100' type='submit'>
					Continue
				</Button>
			</Form>
		</>
	);
}

export default GenerateCode;

// const HOSTNAME = "http://ec2-54-227-18-131.compute-1.amazonaws.com:8000";
const HOSTNAME = "https://prod-backend-service.mysopguide.com";
//const HOSTNAME = "http://dev-mysopguide-backend-env.eba-fsqu2apj.us-east-1.elasticbeanstalk.com";
//const HOSTNAME = "http://127.0.10.1:80";
//Public Site content
export const URL_SITE_CONTENT = HOSTNAME + "/public/home";
export const URL_FAQS = HOSTNAME + "/public/faq";
export const URL_BANNER_LIST = HOSTNAME + "/public/banners";
export const URL_TESTIMONIALS = HOSTNAME + "/public/testimonials";
export const URL_SAMPLE_SOP = HOSTNAME + "/public/sample-sop";
export const URL_SOP_LIST = HOSTNAME + "/public/sop-bundles";
export const URL_CONTACT_US = HOSTNAME + "/public/contact-us";
export const URL_CAREERS = HOSTNAME + "/public/careers";
export const URL_SUBSCRIBE = HOSTNAME + "/public/subscribe";
export const URL_UNSUBSCRIBE = HOSTNAME + "/public/unsubscribe";
export const URL_FEED = HOSTNAME + "/public/feeds";
export const URL_GET_ARTICLE = HOSTNAME + "/public/article";
// export const URL_VIDEOS = HOSTNAME + "/public/videos";
export const URL_ABOUT_US = HOSTNAME + "/public/about-us";

export const URI_ADMIN_SITECONTENT_VIDEOS = HOSTNAME + "/admin/site-content/videos";
export const URI_ADMIN_VIDEOS = HOSTNAME + "/admin/videos";
export const URI_ADMIN_ARTICLE = HOSTNAME + "/admin/article";
export const URI_ADMIN_FAQ = HOSTNAME + "/admin/site/faq/";
export const URI_ADMIN_FEED = HOSTNAME + "/admin/site-content/feed";
export const URI_ADMIN_BANNERS = HOSTNAME + "/admin/banners";
//Login/Signup
export const URL_LOGIN = HOSTNAME + "/public/user/admin/login";
export const URL_SIGNUP = HOSTNAME + "/public/user/signup";
export const URL_CONFIRM_SIGNUP = HOSTNAME + "/public/user/confirm-signup";
export const URL_INITIATE_FORGOT_PASSWORD = HOSTNAME + "/publicuser/forgot-password";
export const URL_VALIDATE_CONFIRMATION_CODE = HOSTNAME + "public/user/validate-confirmation-code";
export const URL_GET_IP_ADDRESS = "https://checkip.amazonaws.com/";

// Admin
export const ADMIN_URL_LIST_SOP = HOSTNAME + "/admin/sop-bundles";
export const URI_ADMIN_SOP_BUNDLES = HOSTNAME + "/admin/sop-bundles";
export const URI_ADMIN_SOP_GROUPS = HOSTNAME + "/admin/sop-groups";
export const URI_ADMIN_ADD_SOP_GROUP = (bundleId) => HOSTNAME + `/admin/sop-bundle/${bundleId}/group`;
export const URI_ADMIN_SOPS = HOSTNAME + "/admin/sop-documents";
export const URL_ADMIN_UPDATE_PAGE = HOSTNAME + "/admin/sop/update-page";
export const URI_ADMIN_SOP_PAGE = (sopId, pageNum) => {
	let url = HOSTNAME + "/admin/sop";
	if (sopId && sopId > 0) {
		url = url + "/" + sopId;
	}
	if (pageNum && pageNum > 0) {
		url = url + "/page/" + pageNum;
	} else {
		url = url + "/page";
	}
	return url;
};
export const URI_ADMIN_SOP = HOSTNAME + "/admin/sop";
export const URI_ADMIN_BUNDLE = HOSTNAME + "/admin/sop-bundle";
export const URI_ADMIN_BUNDLE_ACTIVATE = HOSTNAME + "/admin/active-bundle";
export const URI_ADMIN_BUNDLE_DEACTIVATE = HOSTNAME + "/admin/deactive-bundle";
export const URI_ADMIN_BUNDLE_IMAGE = (sopBundleId) => {
	let url;
	if (sopBundleId && sopBundleId > 0) {
		url = HOSTNAME + "/admin/sop-bundle-image?sop_bundle_id=" + sopBundleId;
	} else {
		url = HOSTNAME + "/admin/sop-bundle-image";
	}
	return url;
};
export const URI_ADMIN_SOP_ATTACHMENT = HOSTNAME + "/admin/sop-attachment";

export const URI_ADMIN_GROUP_ACTIVATE = HOSTNAME + "/admin/active-group";
export const URI_ADMIN_GROUP_DEACTIVATE = HOSTNAME + "/admin/deactive-group";

export const URI_ADMIN_PUBLISH_SOP = HOSTNAME + "/admin/publish-sop";

export const URI_ADMIN_PRODUCT = HOSTNAME + "/admin/product/";
export const URI_ADMIN_PRODUCT_ACTIVE = (productId) => HOSTNAME + "/admin/product/active/" + productId;
export const URI_ADMIN_PRODUCT_INACTIVE = (productId) => HOSTNAME + "/admin/product/inactive/" + productId;

export const URI_ADMIN_USERS = HOSTNAME + "/admin/users";
export const URI_ADMIN_USER_ADD = HOSTNAME + "/admin/user/add";
export const URI_ADMIN_USER = HOSTNAME + "/admin/user";
export const URI_ADMIN_ROLE_LIST = HOSTNAME + "/admin/role/list";
export const URI_ADMIN_ROLE = HOSTNAME + "/admin/role";
// export const URI_ADMINROLE = HOSTNAME + "/adminrole";

export const URI_ADMIN_PERMISSION_LIST = HOSTNAME + "/admin/permission/list";
export const URI_ADMIN_ROLE_PERMISSION = HOSTNAME + "/admin/role-permission";

export const URI_ADMIN_COUPONS = HOSTNAME + "/admin/promo-code";

export const URI_ADMIN_SUBSCRIBERS = HOSTNAME + "/admin/subscribers";

export const URI_ADMIN_REPORT_LIST = HOSTNAME + "/admin/reports/list";
export const URI_ADMIN_REPORT_BY_NAME = HOSTNAME + "/admin/report-by-name";
export const URI_ADMIN_REPORT_ORG_BUNDLE_SUMMARY = HOSTNAME + "/admin/reports/get-org-bundle-summary";
export const URI_ADMIN_REPORT_LOGIN_SUMMARY = HOSTNAME + "/admin/login-summary";

export const URI_DASHBOARD_STAT = HOSTNAME + "/admin/dashboard-overview";
export const URI_DASHBOARD_TOP_ORG = HOSTNAME + "/admin/top-org";
export const URI_DASHBOARD_SOP_SOLD = HOSTNAME + "/admin/get-sold-sop";
export const URI_DASHBOARD_ORG_ADDITION = HOSTNAME + "/admin/get-org-count";
export const URI_DASHBOARD_EARNINGS = HOSTNAME + "/admin/get-earnings";
export const URI_DASHBOARD_SOP_NEAR_EXPIRY = HOSTNAME + "/admin/sop-near-expiry";

export const URI_ADMIN_CUSTOMERS = HOSTNAME + "/admin/customers";

export const URI_ADMIN_ABOUT_US = HOSTNAME + "/admin/site-content/about-us";
export const URI_PUBLIC_RESOURCE_HOME = HOSTNAME + "/public/home";

import React from "react";
import Navbar from "../layout/Navbar";

export const Page401 = () => {
	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4 dashBoard">
				<h2>401 - Unauthorised action. You do not have permission to access the page.</h2>
			</div>
		</>
	);
};

export default Page401;

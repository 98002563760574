import axios from "axios";
import { Fragment, useEffect, useReducer, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, Container, Card, Form, Button, Alert } from "react-bootstrap";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
// import * as CarouselGrid from "react-grid-carousel";
// import ReactImageVideoLightbox from "react-image-video-lightbox";
import {
  URI_ADMIN_ARTICLE,
  URI_ADMIN_BANNERS,
  URI_ADMIN_FEED,
  URI_ADMIN_SITECONTENT_VIDEOS,
  URI_ADMIN_VIDEOS,
  URI_ADMIN_ABOUT_US,
  URI_PUBLIC_RESOURCE_HOME
} from "../../constants";
// import { formatDate } from "../../../utils/dateUtils";
import { useLocation } from "react-router-dom";
import ManageArticle from "./ManageArticle";
import { useLoader } from "../../hooks/useLoader";
import ManageFeed from "./ManageFeed";
import ManageVideo from "./ManageVideo";
// import ManageBanner from "./ManageBanner";

import moment from 'moment-timezone';

const ALERT_CODES = {
  HIDE_ALERT_CODE: -1,
  SUCCESS_CODE: 1,
  FAILURE_CODE: 2,
};

function Resources() {
  const config = useRef();
  const [showAlert, setShowAlert] = useState(ALERT_CODES.HIDE_ALERT_CODE);
  const [showArticle, setShowArticle] = useState(false);
  const [showFeed, setShowFeed] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  // const [showBanner, setShowBanner] = useState(false);
  const bannerImageFile = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { startLoader, stopLoader } = useLoader();

  const resourcesReducer = (state, action) => {
    switch (action.type) {
      case "loadFeeds": {
        return { ...state, feeds: action.feeds };
      }
      case "updateFeed": {
        updateFeed(action.feed);
        return { ...state };
      }
      case "cancelFeed": {
        setShowFeed(false);
        return state;
      }
      case "loadVideos": {
        return { ...state, videos: action.videos };
      }
      case "addVideo": {
        let result = state;
        if (state.videos.length < 5) {
          addVideo(action.video);
        }
        return result;
      }
      case "appendVideoToUI": {
        let existingVideos = state.videos;
        return { ...state, videos: [action.video, ...existingVideos] };
      }
      case "deleteVideoFromUI": {
        let subset = state.videos.filter((v) => v.id !== action.videoID);
        return { ...state, videos: subset };
      }
      case "cancelVideo": {
        setShowVideo(false);
        return state;
      }
      case "loadArticles": {
        return { ...state, articles: action.articles };
      }
      case "addArticle": {
        if (state.articles.length < 11) {
          addArticle(action.article);
        }
        return state;
      }
      case "appendArticleToUI": {
        return { ...state, articles: [action.article, ...state.articles] };
      }
      case "cancelArticle": {
        setShowArticle(false);
        return state;
      }
      case "deleteArticle": {
        let subarticles = state.articles.filter((a) => a.id !== action.articleID);
        return { ...state, articles: subarticles };
      }
      case "loadBanners": {
        return { ...state, banners: action.banners };
      }
      case "addBanner": {
        addBanner(action.banner);
        return state;
      }
      case "appendBannerToUI": {
        return { ...state, banners: [action.banner, ...state.banners] };
      }
      case "removeBannerFromUI": {
        let subsetBanners = state.banners.filter((b) => b !== action.banner);
        return { ...state, banners: subsetBanners };
      }
      case "cancelBanner": {
        // setShowBanner(false);
        return state;
      }
      case "updateAboutUs": {
        return { ...state, aboutUs: action.aboutUs }
      }
      case "saveAboutUs": {
        saveAboutUs(state.aboutUs);
        return state;
      }
      default: {
        return state;
      }
    }
  };
  const emptyAboutUs = { save_hours: 0, active_user: 0, process_covered: 0, download_format: 0 };
  const [resourcesState, dispatch] = useReducer(resourcesReducer, { articles: [], feeds: [], videos: [], banners: [], aboutUs: emptyAboutUs });

  useEffect(() => {
    let idToken;
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const { id_token } = JSON.parse(authToken);
      idToken = id_token;
    }
    config.current = {
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };
    populateData();
  }, [localStorage.getItem("token")]);

  const populateData = () => {
    startLoader();
    let prs = [];
    prs.push(populateArticles());
    prs.push(populateFeeds());
    prs.push(populateVideos());
    prs.push(populateBanners());
    prs.push(populateAboutUs());
    Promise.allSettled(prs).then(() => stopLoader());
  };
  const populateArticles = () => {
    const params = new URLSearchParams(location.search);
    const myParam = params.get("id");
    console.log("hello" + myParam);
    return axios
      .get(URI_ADMIN_ARTICLE, config.current)
      .then((response) => {
        // setArticles(response.data);
        dispatch({ type: "loadArticles", articles: response.data });
      })
      .catch((error) => console.error(error));
  };
  const populateFeeds = () => {
    return axios
      .get(URI_ADMIN_FEED, config.current)
      .then((response) => {
        dispatch({ type: "loadFeeds", feeds: response.data });
      })
      .catch((error) => console.error(error));
  };
  const populateVideos = () => {
    return axios
      .get(URI_ADMIN_SITECONTENT_VIDEOS, config.current)
      .then((response) => {
        dispatch({ type: "loadVideos", videos: response.data });
      })
      .catch((error) => console.error(error));
  };
  const populateBanners = () => {
    return axios
      .get(URI_ADMIN_BANNERS, config.current)
      .then((response) => {
        dispatch({ type: "loadBanners", banners: response.data });
      })
      .catch((error) => console.error(error));
  };

  function handleImageClick(e, index) {
    e.preventDefault();
    setShowModal(true);
    debugger;
    setCurrentIndex(index);
  }

  function onNavigationCallback(currentIndex) {
    setCurrentIndex(currentIndex);
  }

  function addArticle(article) {
    setShowArticle(false);
    startLoader();
    return axios
      .post(URI_ADMIN_ARTICLE, article, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log("article added");
          console.log(response.data);
          dispatch({ type: "appendArticleToUI", article: response.data });
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      })
      .finally(() => stopLoader());
  }
  function deleteArticle(id) {
    startLoader();
    return axios
      .delete(`${URI_ADMIN_ARTICLE}/${id}`, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          dispatch({ type: "deleteArticle", articleID: id });
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      })
      .finally(() => stopLoader());
  }
  function updateFeed(feed) {
    setShowFeed(false);
    return axios
      .put(`${URI_ADMIN_FEED}/${feed.id}`, feed, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      });
  }

  function addVideo(video) {
    setShowVideo(false);
    startLoader();
    let payload = { ...video, active: false };
    return axios
      .post(URI_ADMIN_SITECONTENT_VIDEOS, payload, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          // dispatch({ 'type': 'appendVideoToUI', video: payload });
          populateVideos();
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      })
      .finally(() => stopLoader());
  }
  function deleteVideo(id) {
    startLoader();
    return axios
      .delete(`${URI_ADMIN_VIDEOS}/${id}`, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          dispatch({ type: "deleteVideoFromUI", videoID: id });
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      })
      .finally(() => stopLoader());
  }

  function addBanner(banner) {
    startLoader();
    let formData = new FormData();
    formData.append("file", banner);
    return axios
      .post(`${URI_ADMIN_BANNERS}`, formData, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          // dispatch({ 'type': 'appendBannerToUI', banner: response.data });
          populateBanners();
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      })
      .finally(() => stopLoader());
  }
  function deleteBanner(banner) {
    startLoader();
    return axios
      .delete(`${URI_ADMIN_BANNERS}?filePath=${banner}`, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          dispatch({ type: "removeBannerFromUI", banner: banner });
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      })
      .finally(() => stopLoader());
  }

  function saveAboutUs(aboutUsPayload) {
    return axios
      .post(`${URI_ADMIN_ABOUT_US}`, aboutUsPayload, config.current)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          setShowAlert(ALERT_CODES.SUCCESS_CODE);
        } else {
          setShowAlert(ALERT_CODES.FAILURE_CODE);
        }
      })
      .catch((err) => {
        setShowAlert(ALERT_CODES.FAILURE_CODE);
        var error_received = "";
        if (err.response) {
          error_received = err.response.data.detail;
        }
        console.log("error: ", error_received);
      });
  }

  function populateAboutUs() {
    return axios
      .get(URI_PUBLIC_RESOURCE_HOME, config.current)
      .then((response) => {
        let d = response.data.filter((d) => d.section === "About-Us");
        let abtUs = d && d.length === 1 ? d[0].content : emptyAboutUs;
        dispatch({ type: "updateAboutUs", aboutUs: abtUs });
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      <Navbar />
      <div className="g-0 home-top-padding mb-4 resource">
        <div className="innerHead">
          <h1>Resources</h1>
        </div>
        <div>
          <Alert
            key="success"
            variant="success"
            show={showAlert === ALERT_CODES.SUCCESS_CODE}
            dismissible
            onClose={() => setShowAlert(ALERT_CODES.HIDE_ALERT_CODE)}
          >
            Data successfully saved !
          </Alert>
          <Alert
            key="danger"
            variant="danger"
            show={showAlert === ALERT_CODES.FAILURE_CODE}
            dismissible
            onClose={() => setShowAlert(ALERT_CODES.HIDE_ALERT_CODE)}
          >
            Data save failed !
          </Alert>
        </div>
        <Container fluid="true" className="faq">
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item href="/Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Resources</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <Row className="mb-2">
                <Col className="mt-2">
                  <h4>Videos</h4>
                </Col>
                <Col className="text-end">
                  <Button disabled={resourcesState.videos.length >= 5} onClick={() => setShowVideo(true)}>
                    Add Video
                  </Button>
                </Col>
              </Row>
              <Row className="mb-3 resVideos">
                <Col>
                  <Row>
                    {resourcesState.videos.map(function (item, i) {
                      return (
                        <Fragment key={i}>
                          <Col md={3} className="text-center">
                            <div className="text-center wrapper mb-2">
                              <i className="bi bi-play-circle"></i>
                              <Button onClick={() => deleteVideo(item.id)} title="Delete Video">
                                <i className="bi bi-trash3-fill"></i>
                              </Button>
                            </div>
                            <p>{item.video_id}</p>
                          </Col>
                        </Fragment>
                      );
                    })}
                    {showVideo && <ManageVideo dispatch={dispatch} />}
                  </Row>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mb-2">
                <Col className="mt-2">
                  <h4>Banners</h4>
                </Col>
                <Col className="text-end">
                  {
                    <Button
                      disabled={resourcesState.banners.length >= 5}
                      onClick={() => bannerImageFile.current.click()}
                    >
                      Add Banner
                    </Button>
                  }
                  <Form.Control
                    key="1"
                    ref={bannerImageFile}
                    type="file"
                    hidden
                    accept="image/jpeg,image/png"
                    onChange={(e) => addBanner(e.target.files[0])}
                  />
                </Col>
              </Row>
              <Row className="mb-3 resVideos">
                <Col>
                  <Row>
                    {resourcesState.banners.map(function (item, i) {
                      return (
                        <Fragment key={i}>
                          <Col md={4} className="text-center">
                            <div className="text-center wrapper mb-2">
                              <i className="bi bi-card-image"></i>
                              <Button onClick={() => deleteBanner(item)} title="Delete Banner">
                                <i className="bi bi-trash3-fill"></i>
                              </Button>
                            </div>
                            <p>{item}</p>
                          </Col>
                        </Fragment>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mb-3">
                <Col className="mt-2">
                  <h4>About Us</h4>
                </Col>
                <Col className="text-end">
                  <Button onClick={() => dispatch({ type: 'saveAboutUs' })}>Save</Button>
                </Col>
              </Row>
              <Row className="mb-3 resVideos">
                <Col>
                  <Row className="mb-3">
                    <Col md={6} className="mb-3">
                      <Form.Label>Processes Covered</Form.Label>
                      <Form.Control type="text" id="processValues" value={resourcesState.aboutUs.process_covered} onChange={(e) => dispatch({ type: 'updateAboutUs', aboutUs: {...resourcesState.aboutUs, process_covered: e.target.value }})} />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Label>Downloadable Format</Form.Label>
                      <Form.Control type="text" id="downloadableValues" value={resourcesState.aboutUs.download_format} onChange={(e) => dispatch({ type: 'updateAboutUs', aboutUs: {...resourcesState.aboutUs, download_format: e.target.value }})} />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Active Users</Form.Label>
                      <Form.Control type="text" id="activeuserValues" value={resourcesState.aboutUs.active_user} onChange={(e) => dispatch({ type: 'updateAboutUs', aboutUs: {...resourcesState.aboutUs, active_user: e.target.value }})} />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Hours Saved</Form.Label>
                      <Form.Control type="text" id="hoursavedValues" value={resourcesState.aboutUs.save_hours} onChange={(e) => dispatch({ type: 'updateAboutUs', aboutUs: {...resourcesState.aboutUs, save_hours: e.target.value }})} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mb-3">
                <Col className="mt-2">
                  <h4>Articles</h4>
                </Col>
                <Col className="text-end">
                  <Button disabled={resourcesState.articles.length >= 10} onClick={() => setShowArticle(true)}>
                    Add Article
                  </Button>
                </Col>
              </Row>
              <Row>
                {resourcesState.articles.map(function (item, i) {
                  return (
                    <Col key={i} md={12}>
                      <Card key={i} className="mb-2 article">
                        <Card.Body key={i}>
                          <h5>{item.title}</h5>
                          <i>{item.created_at ? moment.utc(item.created_at).tz("Asia/Kolkata").format('DD-MM-YYYY, h:mm:ss a') : ""}</i>
                          <div>
                            <Button onClick={() => deleteArticle(item.id)} title="Delete Article">
                              <i className="bi bi-trash3-fill"></i>
                            </Button>
                          </div>
                          <p key={i} dangerouslySetInnerHTML={{ __html: item.article_text }}></p>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
                {showArticle && <ManageArticle key="1" dispatch={dispatch} />}
              </Row>
            </Col>
            <Col md={3}>
              <Row className="mb-3">
                <Col className="mt-2">
                  <h4>Feeds</h4>
                </Col>
                <Col className="text-end">
                  <Button onClick={() => setShowFeed(true)}>Edit Feed</Button>
                </Col>
              </Row>
              {resourcesState.feeds
                .filter((s, idx) => idx === 0)
                .map(function (item, i) {
                  return (
                    <>
                      <Row key={i} className="extLink">
                        <Col key={i} dangerouslySetInnerHTML={{ __html: item.plugin_code }}></Col>
                      </Row>
                      {showFeed && <ManageFeed key={i} socialFeed={item} dispatch={dispatch} />}
                    </>
                  );
                })}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}
export default Resources;

import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import validator from "./SopValidator";
import axios from "axios";
import { URI_ADMIN_BUNDLE, URI_ADMIN_BUNDLE_IMAGE } from "../../constants";

const BundleDetails = ({show, setShow, newBundle, setNewBundle}) => {

	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const [bundleImages, setBundleImages] = useState([]);
	const deletedImages = useRef([]);

	const [showAlert, setShowAlert] = useState(-1);

	useEffect(() => {
		populateData();
	}, [newBundle.id]);

	const populateData = () => {
		if (newBundle.id && newBundle.id > 0) {
			axios.get(URI_ADMIN_BUNDLE_IMAGE(newBundle.id), config)
			.then((response) => { 
				console.log(response.statusText);
				if (response.status === 200) {
					setBundleImages(response.data);
				}
			})
			.catch((err) => { console.log("Get bundle images failed" + err) });
		}
	}

	const handleCreateBundleClick = () => {
		if (validator.isTextValid(newBundle.title) && validator.isTextValid(newBundle.description) && validator.isBundleRatingValid(newBundle.rating)) {
			let payload = {...newBundle};
			// handleClose();
			uploadBundleImages(newBundle.id);
			syncDeletedImages();
			return axios.put(URI_ADMIN_BUNDLE + "/" + newBundle.id, payload, config).then((response) => {
				console.log(response);
				if (response.status === 200) {
					setShowAlert(1);
				}
			}).catch((err) => {console.log(err); setShowAlert(2);});
		}
	}
	const uploadBundleImages = (sopBundleId) => {
		let formData = new FormData();
		let imgAdded = false;
		for (let i = 0; i < bundleImages.length; i++) {
			if (bundleImages[i].data) {
				formData.append('imageList', bundleImages[i].data);
				imgAdded = true;
			}
		}
		if (imgAdded) {
			axios.post(URI_ADMIN_BUNDLE_IMAGE(sopBundleId), formData, {...config, "Content-Type": "multipart/form-data"}) 
			.then((response) => { console.log(response.statusText) })
			.catch((err) => { console.log("file upload failed" + err) });
		}
	}
	const appendBundleImage = (imageFile) => {
		let barray = [...bundleImages, {sop_bundle_id: newBundle.id, title: imageFile.filename, data: imageFile}];
		setBundleImages(barray);
	}
	const deleteBundleImage = (index) => {
		deletedImages.current.push(bundleImages[index]);
		let barray = bundleImages.filter((b, idx) => idx !== index)
		setBundleImages(barray);
	}
	const syncDeletedImages = () => {
		deletedImages.current.map((img) => {
			if (img.id && img.id > 0) {
				axios.delete(URI_ADMIN_BUNDLE_IMAGE(0) + "?bundle_img_id=" + img.id, config)
				.then((response) => { console.log(response) })
				.catch((err) => console.error(err))
			}
		});
		deleteBundleImage.current = [];
	}

	return (
		<Modal show={show} onHide={(e) => setShow(false)} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>Add SOP Bundle</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<Alert key="success" variant="success" show={showAlert === 1} dismissible onClose={() => setShowAlert(-1)} >
						Transaction successful !
					</Alert>
					<Alert key="danger" variant="danger" show={showAlert === 2} dismissible onClose={() => setShowAlert(-1)} >
						Transaction failed !
					</Alert>
				</div>
				<Form noValidate >
					<Form.Group className="mb-3" controlId="sopBundleName">
						<Form.Label className="d-none">SOP Bundle Name</Form.Label>
						<Form.Control type="text" placeholder="SOP Bundle Name" maxLength="50" required isInvalid={!validator.isTextValid(newBundle.title)} value={newBundle ? newBundle.title : ""}
							onChange={(e) => {
								setNewBundle({ ...newBundle, title: e.target.value });
							}} />
						<Form.Control.Feedback type="invalid">
							Please provide a bundle title.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group className="mb-3" controlId="sopBundleDesc">
						<Form.Label className="d-none">Description</Form.Label>
						<Form.Control as="textarea" rows={3} placeholder="Description" maxLength="200" required isInvalid={!validator.isTextValid(newBundle.description)} value={newBundle ? newBundle.description : ""}
							onChange={(e) => {
								setNewBundle({ ...newBundle, description: e.target.value });
							}} />
						<Form.Control.Feedback type="invalid">
							Please provide a description.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>Rating</Form.Label>
						<Form.Control type="number" name="rating" required isInvalid={!validator.isBundleRatingValid(newBundle.rating)} value={newBundle.rating ? newBundle.rating : 0} onChange={(e) => setNewBundle({...newBundle, rating: e.target.value})} />
						<Form.Control.Feedback type="invalid">
							Please provide a Rating between 1 and 5.
						</Form.Control.Feedback>
					</Form.Group>
					<Row>
						<Col md={8}>
							<Form.Group controlId="bundleImg" className="mb-3">
								<Form.Control type="file" disabled={bundleImages.length >= 5} accept="image/jpeg,image/png" onChange={(e) => appendBundleImage(e.target.files[0])} />
							</Form.Group>
						</Col>
					</Row>
					<Row className="b-images">
						<Col className="d-flex">
							{bundleImages.map((bi, idx) => {
								return (<div className="thumb">
									{bi.data ? 
									<img className="d-block h-100" src={URL.createObjectURL(bi.data)} alt="thumb" title={bi.title} /> : 
									<img className="d-block h-100" src={bi.path} alt="thumb" title={bi.title} /> }
									<div className="close">
										<Button variant="link" onClick={(e) => deleteBundleImage(idx)}>
											<i className="bi bi-x-circle-fill"></i>
										</Button>
									</div>
								</div>)
							})}
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-primary" onClick={(e) => setShow(false)}>
					Close
				</Button>
				<Button variant="primary" onClick={(e) => handleCreateBundleClick()}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default BundleDetails;
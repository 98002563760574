import { Row, Col, Card, Button, Container, Breadcrumb, Form, Table, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useReducer, useState, useEffect, startTransition } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import ImgLogo from "../../assets/images/sop_logo.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { SOPS, SUCCESS } from "../../pageLocations";
import { URI_ADMIN_PUBLISH_SOP, URL_ADMIN_UPDATE_PAGE, URI_ADMIN_SOP, URI_ADMIN_SOP_PAGE } from "../../constants";
import SopReducer from "./SopReducer";
import validator from "./SopValidator";

let initialSop = { title: "", description: "", page: { sop_header_blocks: [], sop_body_blocks: [] } };

function MySOP() {
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const navigate = useNavigate();
	const [sopState, dispatch] = useReducer(SopReducer, initialSop);
	const [validated, setValidated] = useState(false);

	let [bundles, setBundles] = useState([]);
	let [groups, setGroups] = useState([]);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [showAlert, setShowAlert] = useState(-1);

	useEffect(() => {
		console.log("calling populateData.");
		populateData();
	}, []);

	const saveSop = (sop) => {
		if (sop) {
			let payload = { ...sop };
			if (!sop.document_code) {
				payload.document_code = "1.0";
			}
			if (!sop.active) {
				payload.active = false;
			}

			if (sop.id && sop.id > 0) {
				let url = URI_ADMIN_SOP + "/" + sop.id;
				payload.id = sop.id;

				console.log(payload);
				axios
					.put(url, payload, config)
					.then((response) => {
						console.log(response);

						if (response.status === 200) {
							setShowAlert(1);
							saveSopPage(sop.id, sop.page);
						} else {
							setShowAlert(2);
						}
					})
					.catch((e) => {
						setShowAlert(2);
						console.log(e);
					});
			} else {
				axios
					.post(URI_ADMIN_SOP, payload, config)
					.then((response) => {
						console.log(response);
						if (response.status === 200) {
							setShowAlert(1);
							let s = response.data;
							saveSopPage(s.id, sop.page);
							dispatch({ type: "modifySopId", sop: { id: s.id } });
						} else {
							setShowAlert(2);
						}
					})
					.catch((e) => {
						setShowAlert(2);
						console.log(e);
					});
			}
		} else {
			console.log("Missing sop object.");
		}
	};

	const saveSopPage = (sopId, page) => {
		if (page) {
			let payload = { ...page, sop_document_id: sopId };

			if (!page.active) {
				payload.active = false;
			}

			if (page.page_num && page.page_num > 0) {
				console.log("inside page_num " + page.page_num);
				// if (page.id && page.id > 0) {
				let url = URL_ADMIN_UPDATE_PAGE;
				axios
					.post(url, payload, config)
					.then((response) => {
						console.log(response);
					})
					.catch((e) => console.log(e));
			} else {
				let url = URI_ADMIN_SOP_PAGE(0, 0);
				payload.page_num = 1;
				axios
					.post(url, payload, config)
					.then((response) => {
						console.log(response);
						if (response.status === 200) {
							let updatedPage = response.data;
							let updatedSop = { ...sopState, page: updatedPage };
							dispatch({
								type: "editSop",
								sop: updatedSop,
							});
						}
					})
					.catch((e) => console.log(e));
			}
		}
	};

	const publishSopPage = (sopPage) => {
		let payload = { ...sopPage, page_num: 1 };

		axios
			.post(URI_ADMIN_PUBLISH_SOP, payload, config)
			.then((response) => {
				if (response.status === 200) {
					setShowAlert(1);
					console.log(response);
				} else {
					setShowAlert(2);
				}
			})
			.catch((e) => {
				setShowAlert(2);
				console.log(e);
			});
	};

	const handleBundleSelectionChange = (id) => {
		let subsetGrps = [];
		if (id) {
			let bundleId = parseInt(id);
			let grps = JSON.parse(localStorage.getItem("sopGroups"));
			subsetGrps = grps.filter((g) => g.sop_bundle_id === bundleId);
		}
		setGroups(subsetGrps);

		dispatch({ type: "modifyBundleId", sop: { sop_bundle_id: id } });
		dispatch({ type: "modifyGroupId", sop: { sop_group_id: -1 } });
	};

	const populateData = () => {
		let bdls = JSON.parse(localStorage.getItem("sopBundles"));
		setBundles(bdls);

		let sopContext = JSON.parse(localStorage.getItem("sopContext"));

		if (sopContext) {
			sopContext.body_section_title = sopContext.title;
		}
		if (sopContext && sopContext.sop_bundle_id) {
			handleBundleSelectionChange(sopContext.sop_bundle_id);
		}

		if (!sopContext) {
			sopContext = { title: "Demo Title", description: "Demo Description" };
		}

		dispatch({
			type: "editSop",
			sop: sopContext,
		});
	};

	const handleSaveClick = async () => {
		if (validator.isDataValid(sopState) && validator.isPageDataValid(sopState)) {
			saveSop(sopState);
			// navigate(SOPS);
			console.log("saved");
		} else {
			console.log("invalid data");
		}
	};

	const handlePublishClick = () => {
		if (validator.isDataValid(sopState) && validator.isPageDataValid(sopState)) {
			publishSopPage(sopState.page);
		}
	};

	const stepColors = ["step orange", "step yellow", "step green", "step blue", "step indigo"];

	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4">
				<div className="innerHead">
					<h1>Manage SOPs</h1>
				</div>
				<Form noValidate validated={validated}>
					<Container fluid="true" className="faq order">
						<Row>
							<Col>
								<Breadcrumb>
									<Breadcrumb.Item href="/Home">Home</Breadcrumb.Item>
									<Breadcrumb.Item href={SOPS}>SOPs</Breadcrumb.Item>
									<Breadcrumb.Item active>Edit SOP Content</Breadcrumb.Item>
								</Breadcrumb>
							</Col>
						</Row>
						<div>
							<Alert
								key="success"
								variant="success"
								show={showAlert === 1}
								dismissible
								onClose={() => setShowAlert(-1)}
							>
								Data saved successfully!
							</Alert>
							<Alert key="danger" variant="danger" show={showAlert === 2} dismissible onClose={() => setShowAlert(-1)}>
								Data saved failed!
							</Alert>
						</div>
						<Row>
							<Col>
								<h4>Edit SOP Content</h4>
							</Col>
							<Col className="text-end mySOP-controls">
								<Button variant="primary me-2" onClick={handleSaveClick}>
									Save
								</Button>
								<Button
									variant="outline-primary me-2"
									onClick={(e) =>
										startTransition(() => {
											navigate(SOPS);
										})
									}
								>
									Cancel
								</Button>
								<Button variant="outline-primary me-2" onClick={handleShow}>
									History
								</Button>
								<Button
									variant="outline-primary me-2"
									disabled={sopState && sopState.page.id ? false : true}
									onClick={(e) => handlePublishClick()}
								>
									Publish
								</Button>
							</Col>
						</Row>
						<Row>
							<Col className="mb-3 mt-3">
								<div className="formGroup mb-3">
									<Row>
										<Col>
											<h6>SOP Details</h6>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<Form.Group className="mb-3" controlId="sopTitle">
												<Form.Label className="d-none">SOP Title</Form.Label>
												<Form.Control
													type="text"
													placeholder="SOP Title"
													maxLength="50"
													required
													isInvalid={!validator.isTitleValid(sopState)}
													value={sopState && sopState.title ? sopState.title : ""}
													onChange={(e) => dispatch({ type: "modifySopTitle", sop: { title: e.target.value } })}
												/>
												<Form.Control.Feedback type="invalid">Please provide a Title.</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col md={4}>
											<Form.Group className="mb-3" controlId="sopDesc">
												<Form.Label className="d-none">SOP Description</Form.Label>
												<Form.Control
													type="text"
													placeholder="SOP Description"
													maxLength="50"
													required
													isInvalid={!validator.isDescriptionValid(sopState)}
													value={sopState && sopState.description ? sopState.description : ""}
													onChange={(e) =>
														dispatch({ type: "modifySopDescription", sop: { description: e.target.value } })
													}
												/>
												<Form.Control.Feedback type="invalid">Please provide a Description.</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col md={4}>
											<Form.Group className="mb-3" controlId="workSelect">
												<Form.Select
													aria-label="Default select example"
													required
													isInvalid={!validator.isBundleIdValid(sopState)}
													value={sopState ? sopState.sop_bundle_id : -1}
													onChange={(e) => handleBundleSelectionChange(e.target.value)}
												>
													<option key={-1} value={-1}>
														Select SOP Bundle
													</option>
													{bundles.map((s, idx) => {
														return (
															<option key={s.id} value={s.id}>
																{s.title}
															</option>
														);
													})}
												</Form.Select>
												<Form.Control.Feedback type="invalid">Please select a valid bundle.</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col md={4}>
											<Form.Group className="mb-1" controlId="group">
												<Form.Select
													aria-label="Default select example"
													required
													isInvalid={!validator.isGroupIdValid(sopState)}
													value={sopState.sop_group_id ? sopState.sop_group_id : -1}
													onChange={(e) => dispatch({ type: "modifyGroupId", sop: { sop_group_id: e.target.value } })}
												>
													<option key={-1} value={-1}>
														Select SOP Group
													</option>
													{groups.map((g, idx) => {
														return (
															<option key={g.id} value={g.id}>
																{g.title}
															</option>
														);
													})}
												</Form.Select>
												<Form.Control.Feedback type="invalid">Please select a valid group.</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<Card className="mySOP-cont">
									<Card.Body>
										<Container fluid="true">
											<Row className="mb-2">
												<Col md={10}>
													<Row>
														<Col>
															<Table className="mb-0">
																<thead>
																	<tr>
																		{!sopState ? (
																			<th></th>
																		) : (
																			sopState.page.sop_header_blocks.map((h, idx) => {
																				return (
																					<th key={idx}>
																						<Form.Control type="text" value={h.title ? h.title : ""} />
																					</th>
																				);
																				/* onChange={(e) => dispatch({type: "modifyHeaderTitle", sop: {headerIndex: idx, title: e.target.value}})} */
																			})
																		)}
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		{!sopState ? (
																			<td></td>
																		) : (
																			sopState.page.sop_header_blocks.map((h, idx) => {
																				return (
																					<td key={idx}>
																						<Form.Group>
																							<Form.Control
																								as="textarea"
																								rows={2}
																								maxLength="200"
																								required
																								isInvalid={!validator.isTextValid(h.text_content)}
																								value={h.text_content ? h.text_content : ""}
																								onChange={(e) =>
																									dispatch({
																										type: "modifyHeaderContent",
																										sop: { headerIndex: idx, text_content: e.target.value },
																									})
																								}
																							/>
																							<Form.Control.Feedback type="invalid">
																								Please provide header content.
																							</Form.Control.Feedback>
																						</Form.Group>
																					</td>
																				);
																			})
																		)}
																	</tr>
																</tbody>
															</Table>
														</Col>
													</Row>
												</Col>
												<Col md={2} className="ps-0">
													<div className="mySOP-logo">
														<img className="d-block w-100" src={ImgLogo} alt="Logo" />
													</div>
												</Col>
											</Row>

											<Row>
												<Col md={10}>
													<Row>
														<Col>
															<Form.Group>
																<Form.Label>{sopState ? sopState.document_code : ""}</Form.Label>
																<Form.Control
																	type="text"
																	maxLength="50"
																	required
																	isInvalid={!validator.isTextValid(sopState.body_section_title)}
																	value={sopState ? sopState.body_section_title : ""}
																	onChange={(e) =>
																		dispatch({
																			type: "modifyBodySectionTitle",
																			sop: { body_section_title: e.target.value },
																		})
																	}
																/>
																<Form.Control.Feedback type="invalid">
																	Please provide section title.
																</Form.Control.Feedback>
															</Form.Group>
														</Col>
													</Row>
													<Row className="arrow-steps clearfix p-2 me-2">
														{!(sopState && sopState.page && sopState.page.sop_body_blocks) ? (
															<Col className={stepColors[0]} />
														) : (
															sopState.page.sop_body_blocks.map((b, idx) => {
																return (
																	<Col className={stepColors[idx % stepColors.length]}>
																		<OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{b.title}</Tooltip>} >
																			<Form.Group>
																				<Form.Control
																					className={stepColors[idx % stepColors.length]}
																					type="text"
																					maxLength="200"
																					required
																					isInvalid={!validator.isTextValid(b.title)}
																					value={b.title ? b.title : ""}
																					onChange={(e) =>
																						dispatch({
																							type: "modifyBodyTitle",
																							sop: { bodyIndex: idx, title: e.target.value },
																						})
																					}
																				/>
																				<Form.Control.Feedback type="invalid">
																					Please provide body title.
																				</Form.Control.Feedback>
																			</Form.Group>
																		</OverlayTrigger>
																	</Col>
																);
															})
														)}
													</Row>
													<Row>
														<Col className="mb-2">
															<b>Steps to follow / Work instractions</b>
														</Col>
													</Row>
													<Row className="steps-divider">
														{!(sopState && sopState.page && sopState.page.sop_body_blocks) ? (
															<Col />
														) : (
															sopState.page.sop_body_blocks.map((b, idx) => {
																return (
																	<Col>
																		<Form.Group>
																			<Form.Control
																				as="textarea"
																				rows={15}
																				maxLength="2000"
																				required
																				isInvalid={!validator.isTextValid(b.text_content)}
																				value={b.text_content ? b.text_content : ""}
																				onChange={(e) =>
																					dispatch({
																						type: "modifyBodyContent",
																						sop: { bodyIndex: idx, text_content: e.target.value },
																					})
																				}
																			/>
																			<Form.Control.Feedback type="invalid">
																				Please provide body content.
																			</Form.Control.Feedback>
																		</Form.Group>
																	</Col>
																);
															})
														)}
													</Row>
												</Col>
												<Col md={2} className="ps-0">
													<Row>
														<Col className="mb-2">
															<div className="steps-head">Remarks</div>
														</Col>
													</Row>
													<Row>
														<FloatingLabel label="Comments">
															<Form.Control
																as="textarea"
																placeholder="Leave a comment here"
																style={{ height: "200px" }}
																maxLength="500"
																value={sopState && sopState.page && sopState.page.remarks ? sopState.page.remarks : ""}
																onChange={(e) => dispatch({ type: "modifyRemarks", sop: { remarks: e.target.value } })}
															/>
														</FloatingLabel>
													</Row>

													{sopState && sopState.page.id ? 
													<>
													<Row>
														<Col className="mb-2">
															<div className="steps-head">Update Summary</div>
														</Col>
													</Row>
													<Row>
														<FloatingLabel label="Comments">
															<Form.Control
																as="textarea"
																placeholder="Leave a comment here"
																style={{ height: "200px" }}
																maxLength="1000"
																required
																isInvalid={!validator.isTextValid(sopState.page.update_summary)}
																value={sopState && sopState.page && sopState.page.update_summary ? sopState.page.update_summary : ""}
																onChange={(e) => dispatch({ type: "modifyUpdateSummary", sop: { update_summary: e.target.value } })}
															/>
															<Form.Control.Feedback type="invalid">
																Please provide update summary.
															</Form.Control.Feedback>
														</FloatingLabel>
													</Row>
													</>
													: ""
													}
												</Col>
											</Row>
										</Container>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</Form>
			</div>
			<Footer />
			<Offcanvas show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>SOP History Log</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists,
					etc.
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

export default MySOP;

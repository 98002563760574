import { Row, Col, Button, Container, Breadcrumb, Form, Table, Alert } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import {
	URI_ADMIN_ROLE_LIST,
	URI_ADMIN_ROLE,
	URI_ADMIN_PERMISSION_LIST,
	URI_ADMIN_ROLE_PERMISSION,
} from "../../constants.js";
import axios from "axios";
import { useLoader } from "../../hooks/useLoader";

function Roles() {
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const { startLoader, stopLoader } = useLoader();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);
	const [showAlert, setShowAlert] = useState(-1);

	const rolesResultSet = useRef([]);
	const [roles, setRoles] = useState([]);
	const [editRole, setEditRole] = useState({});
	const [permissions, setPermissions] = useState([]);
	const permissionIdNameMap = useRef({});
	const [rolePermissions, setRolePermissions] = useState([]);

	useEffect(() => {
		populateData();
	}, []);

	const populateData = async () => {
		startLoader();
		let prs = [];
		prs.push(populateRoles());
		prs.push(populatePermissions());
		prs.push(populateRolePermissions());
		Promise.allSettled(prs).then(() => stopLoader());
	};
	const populateRoles = () => {
		return axios
			.get(URI_ADMIN_ROLE_LIST, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let rls = response.data;
					setRoles(rls);
					rolesResultSet.current = rls;
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const populatePermissions = () => {
		return axios
			.get(URI_ADMIN_PERMISSION_LIST, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let prms = response.data;
					setPermissions(prms);
					prms.map((p) => (permissionIdNameMap[p.id] = p.display_title));
				}
			})
			.catch((err) => console.log(err));
	};
	const populateRolePermissions = () => {
		return axios
			.get(URI_ADMIN_ROLE_PERMISSION, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let prms = response.data;
					setRolePermissions(prms ? prms : []);
				}
			})
			.catch((err) => console.log(err));
	};

	const handleRoleSearchTextChange = (text) => {
		let rls = rolesResultSet.current;
		if (text) {
			let subset = rls.filter((r) => r.title.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setRoles(subset);
		} else {
			setRoles(rls);
		}
	};

	const handleSaveRoleClick = (event) => {
		event.preventDefault();
		const data = { title: editRole.title, role_priority: 5, active: true };
		console.log(data);

		if (!validateRole(editRole)) {
			console.log("Missing data. Check all field values.");
			return;
		}

		startLoader();
		let promise;
		if (editRole.id && editRole.id > 0) {
			let payload = { ...data, id: editRole.id, created_by: editRole.created_by };
			promise = axios.put(URI_ADMIN_ROLE + "/" + editRole.id, payload, config);
		} else {
			promise = axios.post(URI_ADMIN_ROLE, data, config);
		}
		postProcess(promise, editRole.privileges);
	};
	const postProcess = (pr, privileges) => {
		pr.then((response) => {
			if (response.status === 200) {
				let updatedRole = response.data;
				let payload = privileges.map((pid) => {
					return { role_id: updatedRole.id, permission_id: pid };
				});
				axios
					.post(URI_ADMIN_ROLE_PERMISSION, payload, config)
					.then((r) => (r.status === 200 ? setShowAlert(1) : setShowAlert(2)))
					.catch((err) => setShowAlert(2))
					.finally(() => {
						populateRoles();
						populateRolePermissions();
					});
			} else {
				setShowAlert(2);
			}
		})
			.catch((error) => {
				console.log(error);
				setShowAlert(2);
			})
			.finally(() => {
				setShow(false);
				stopLoader();
			});
	};

	const validateRole = (role) => {
		return isValidText(role.title);
	};

	const isValidText = (text) => {
		return text && text.trim() !== "";
	};

	const isValidNumber = (number) => {
		return number && number > 0;
	};

	const handleAddRoleClicked = () => {
		setEditRole({ privileges: [] });
		setShow(true);
	};

	const handleEditRoleClicked = (index) => {
		if (index > -1 && index < roles.length) {
			let role = roles[index];
			let perms = rolePermissions.filter((rp) => rp.role_id === role.id).map((rp) => rp.permission_id);
			setEditRole({ ...role, privileges: perms });
			setShow(true);
		}
	};

	const handleDeleteRoleClicked = (index) => {
		if (index > -1 && index < roles.length) {
			let role = roles[index];
			axios
				.delete(URI_ADMIN_ROLE + "/" + role.id, config)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						setShowAlert(1);
					} else {
						setShowAlert(2);
					}
				})
				.catch((error) => {
					console.log(error);
					setShowAlert(2);
				});
		}
	};

	const handleRoleFilterRoleChange = (roleId) => {
		let rls = rolesResultSet.current;
		if (roleId) {
			let subset = rls.filter((u) => u.title.toLowerCase() === roleId.toLowerCase());
			setRoles(subset);
		} else {
			setRoles(rls);
		}
	};

	const handlePermissionChange = (permId, checked) => {
		let priv = editRole.privileges;
		let upd;
		if (checked) {
			upd = { ...editRole, privileges: [...priv, permId] };
		} else {
			let sub = priv.filter((p) => p !== permId);
			upd = { ...editRole, privileges: sub };
		}
		setEditRole(upd);
	};

	return (
		<>
			<Navbar />
			<div className='g-0 home-top-padding mb-4'>
				<div>
					<Alert key='success' variant='success' show={showAlert === 1} dismissible onClose={() => setShowAlert(-1)}>
						Role updated successfully.
					</Alert>
					<Alert key='danger' variant='danger' show={showAlert === 2} dismissible onClose={() => setShowAlert(-1)}>
						Unknown error occured while updating role.
					</Alert>
				</div>
				<div className='innerHead'>
					<h1>Manage Roles</h1>
				</div>
				<Container fluid='true' className='manageSOP'>
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href='/Home'>Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Roles</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<h4>Roles List</h4>
						</Col>
						<Col md={4} className='text-end'>
							<Button variant='primary' onClick={(e) => handleAddRoleClicked()}>
								Add Role
							</Button>
						</Col>
					</Row>
					<Row className='formGroup mb-4 mt-3'>
						<Col md={6}>
							<Row xs='auto'>
								<Col>
									<Form.Control
										type='search'
										placeholder='Search by name'
										className='search-icon me-2'
										aria-label='Search'
										onChange={(e) => handleRoleSearchTextChange(e.target.value)}
									/>
								</Col>
							</Row>
						</Col>
						<Col className='text-end' style={{ display: "none" }}>
							<Row xs='auto' className='float-right'>
								<Col className='mt-2 g-0'>Filter by:</Col>
								<Col>
									<Form.Select aria-label='Role' onChange={(e) => handleRoleFilterRoleChange(e.target.value)}>
										<option key='0' value=''></option>
										{rolesResultSet.current.map((r, idx) => {
											return (
												<option key={idx} value={r.title}>
													{r.title}
												</option>
											);
										})}
									</Form.Select>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped>
								<thead>
									<tr>
										<th>#</th>
										<th>Title</th>
										<th>Permissions</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{roles
										.sort((a, b) => (a.role_priority > b.role_priority ? 1 : -1))
										.map((r, idx) => {
											return (
												<tr key={idx}>
													<td>{idx + 1}</td>
													<td width='15%'>{r.title}</td>
													<td>
														{rolePermissions
															.filter((rp) => rp.role_id === r.id)
															.map((rp) => permissionIdNameMap[rp.permission_id])
															.join(", ")}
													</td>
													<td width='10%'>
														{r.role_priority != 3 && r.role_priority != 4 && (
															<>
																<Button variant='link' onClick={(e) => handleEditRoleClicked(idx)}>
																	<i className='bi bi-pencil'></i>
																</Button>
																<Button variant='link' onClick={(e) => handleDeleteRoleClicked(idx)}>
																	<i className='bi bi-trash'></i>
																</Button>
															</>
														)}
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />

			<Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Add Role</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row>
							<Col md={6}>
								<Form.Group className='mb-3' controlId='firstname'>
									<Form.Label className='d-none'>Title</Form.Label>
									<Form.Control
										type='text'
										placeholder='Title'
										isInvalid={!isValidText(editRole.title)}
										value={editRole.title ? editRole.title : ""}
										onChange={(e) => setEditRole({ ...editRole, title: e.target.value })}
									/>
									<Form.Control.Feedback type='invalid'>Please provide title.</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							{permissions.map((p, idx) => {
								return (
									<Col key={idx} md={6}>
										<Form.Group className='mb-3' controlId='lastname'>
											{editRole && editRole.privileges && editRole.privileges.includes(p.id) ? (
												<Form.Check
													label={p.display_title}
													checked
													onChange={(e) => handlePermissionChange(p.id, e.target.checked)}
												></Form.Check>
											) : (
												<Form.Check
													label={p.display_title}
													onChange={(e) => handlePermissionChange(p.id, e.target.checked)}
												></Form.Check>
											)}
										</Form.Group>
									</Col>
								);
							})}
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='outline-primary' onClick={handleClose}>
						Close
					</Button>
					<Button variant='primary' onClick={(e) => handleSaveRoleClick(e)}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Roles;

import { Nav, Navbar, Row, Col, Button, Container, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "react-bootstrap-submenu/dist/index.css";
import ImgLogo from "../assets/images/sop_logo.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
	COUPONS,
	CUSTOMERS,
	HOME,
	LOGIN,
	PRODUCTS,
	RESOURCES_VIDEOS,
	ROLES,
	SOPS,
	SUBSCRIBERS,
	USERS,
} from "../pageLocations";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
// import AuthContext from "../context/AuthProvider";

export const SiteNavbar = () => {
	const navigate = useNavigate();

	let idToken;
	const authToken = localStorage.getItem("token");
	// console.log("authToken : " + authToken);

	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}

	// const { isAuthenticated, userData } = AuthContext;
	// useEffect(() => {
	//   if (isAuthenticated) {
	//     // getGst();
	//   } else {
	//     // setGST(null)
	//     startTransition(() => {
	//       navigate(LOGIN);
	//     });
	//   }
	// }, [isAuthenticated])

	let bt = (
		<Button
			variant='secondary'
			onClick={() => {
				startTransition(() => {
					navigate(LOGIN);
				});
			}}
		>
			LogIn
		</Button>
	);
	if (idToken) {
		bt = (
			<Button variant='secondary' onClick={(e) => logout()}>
				Logout
			</Button>
		);
	}

	const logout = () => {
		localStorage.clear("token");
		startTransition(() => {
			navigate(LOGIN);
		});
	};

	return (
		<div>
			<Navbar expand='lg' className='fixed-top'>
				<Container fluid className='g-0'>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Brand href='/Home'>
						<img src={ImgLogo} height={44} alt='SOP' />
					</Navbar.Brand>

					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='me-auto'>
							<Nav.Link href={HOME}>Dashboard</Nav.Link>
							<Nav.Link href={USERS}>Users</Nav.Link>
							<Nav.Link href={ROLES}>Roles</Nav.Link>
							<Nav.Link href={PRODUCTS}>Products</Nav.Link>
							<Nav.Link href={CUSTOMERS}>Customers</Nav.Link>
							<Nav.Link href={SOPS}>SOP</Nav.Link>
							<Nav.Link href={COUPONS}>Coupons</Nav.Link>
							<Nav.Link href={SUBSCRIBERS}>Subscribers</Nav.Link>
							<NavDropdownMenu title='Reports' id='reports'>
								<NavDropdown.Item href='/reports/login-summary'>Login summary</NavDropdown.Item>
								<DropdownSubmenu href='' title='Bundle Usage & views'>
									<NavDropdown.Item href='/reports/org-reports'>Organization Wise</NavDropdown.Item>
									<NavDropdown.Item href='/reports/sop-bundle-reports'>SOP Bundle Wise</NavDropdown.Item>
								</DropdownSubmenu>
							</NavDropdownMenu>
							<NavDropdownMenu title='Site Content' id='siteContent'>
								<NavDropdown.Item href={RESOURCES_VIDEOS}>Resources</NavDropdown.Item>
							</NavDropdownMenu>
						</Nav>
					</Navbar.Collapse>
					<Nav className='d-flex'>{bt}</Nav>
				</Container>
			</Navbar>
			<Container fluid className='deviceTabs'>
				<Row>
					<Col className='g-0'>
						<a href='/' title='Search'>
							<i className='bi bi-search'></i>
						</a>
					</Col>
					<Col className='g-0'>
						<a href='/' title='Search'>
							<i className='bi bi-question-circle'></i>
						</a>
					</Col>
					<Col className='g-0'>
						<a href='/' title='Search'>
							<i className='bi bi-bell'></i>
							<span className='cart-order'>10</span>
						</a>
					</Col>
					<Col className='g-0'>
						<a href='/' title='Search'>
							<i className='bi bi-file-earmark-text'></i>
						</a>
					</Col>
					<Col className='g-0'>
						<a href='/' title='Search'>
							<i className='bi bi-cart2'></i>
							<span className='cart-order'>0</span>
						</a>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SiteNavbar;

import { useEffect, useRef, useState } from "react";
import { URI_ADMIN_SUBSCRIBERS } from "../../constants";
import { Breadcrumb, Col, Container, Form, Row, Table } from "react-bootstrap";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import axios from "axios";
import moment from "moment";
import { useLoader } from "../../hooks/useLoader";

function ManageSubscribers() {
    const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

    const [subscribers, setSubscribers] = useState([]);
    const subscribersResultset = useRef([]);
	const { startLoader, stopLoader } = useLoader();

    useEffect(() => {
		startLoader();
		let pr = populateData();
		Promise.resolve(pr).then(() => stopLoader());
	}, []);

    const populateData = async () => {
        return axios.get(URI_ADMIN_SUBSCRIBERS, config)
      	.then((response) => {
			console.log(response);
			if (response.status === 200) {
				let subs = response.data;
				setSubscribers(subs);
				subscribersResultset.current = subs;
			}
      	})
		.catch((err) => {
			var error_received = "";
			if (err.response) {
				error_received = err.response.data.detail;
			}
			console.log("error: ", error_received);
		});
    }

    const handleSubscriberSearchChange = (text) => {
		let subs = subscribersResultset.current;
		if (text) {
			let subset = subs.filter((s) => s.email.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setSubscribers(subset);
		} else {
			setSubscribers(subs);
		}
	};

    return (
        <>
			<Navbar />
			<div className="g-0 home-top-padding mb-4">
				<div className="innerHead">
					<h1>Manage Subscription</h1>
				</div>
				<Container fluid="true" className="manageSOP">
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Subscriptions</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<h4>Subscription List</h4>
						</Col>
					</Row>
					<Row className="formGroup mb-4 mt-3">
						<Col md={6}>
							<Row xs="auto">
								<Col>
									<Form.Control
										type="search"
										placeholder="Search by name"
										className="search-icon me-2"
										aria-label="Search"
										onChange={(e) => handleSubscriberSearchChange(e.target.value)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped>
								<thead>
									<tr>
										<th>#</th>
										<th>Email</th>
										<th>Reason for Unsubscribe</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{subscribers.map((s, idx) => {
										return (
											<tr key={idx}>
												<td>{idx+1}</td>
												<td>{s.email}</td>
												<td>{s.reason_for_unsubscribe}</td>
                                                <td>{s.active ? "Active" : "Unsubscribe"}</td>
                                                <td>{moment(new Date(s.created_at)).format('DD-MM-YYYY')}</td>
												{/* <td className="d-flex">
													<Button variant="link" onClick={(e) => {handleEditCoupon(c); e.stopPropagation()}} >
														<i className="bi bi-pencil"></i>
													</Button>{" "}
													<Button variant="link" onClick={(e) => {deleteCoupon.current = c; setShowDeleteConfirmation(true)}}>
														<i className="bi bi-trash"></i>
													</Button>
												</td> */}
											</tr>
											)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />

			{/* <CouponDetails show={show} setShow={setShow} coupon={editCoupon} setCoupon={setEditCoupon} setShowAlert={setShowAlert} ALERT_CODES={ALERT_CODES} reloadCoupons={reloadCoupons} /> */}
			{/* <Modal show={showDeleteConfirmation} onHide={(e) => setShowDeleteConfirmation(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>Do you really want to delete Coupon "{deleteCoupon.current.code_text}" ? </Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={(e) => setShowDeleteConfirmation(false)}>Cancel</Button>
					<Button variant="primary" onClick={(e) => handleDeleteCoupon(deleteCoupon.current) }>Delete</Button>
				</Modal.Footer>
			</Modal> */}
		</>
    )
}

export default ManageSubscribers;
import { Row, Col, Button, Container, Breadcrumb, Form, Table, Alert } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import { PRODUCT_COMBOS } from "../../pageLocations";
// import ProductReducer from "./ProductReducer";
import axios from "axios";
import { URI_ADMIN_PRODUCT, URI_ADMIN_PRODUCT_ACTIVE, URI_ADMIN_PRODUCT_INACTIVE } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../hooks/useLoader";

function ManageProducts() {
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	const navigate = useNavigate();
	const { startLoader, stopLoader } = useLoader();

	const [products, setProducts] = useState([]);
	const productsResultSet = useRef([]);

	const [showAlert, setShowAlert] = useState(-1);

	// const isAuthenticated = () => {
	// 	return false;
	// };

	useEffect(() => {
		// console.log("this is a test....!!!");
		startLoader();
		let pr = populateData();
		Promise.resolve(pr).then(() => stopLoader());
	}, []);

	// const { productState, dispatch } = useReducer(ProductReducer, [{ id: 0, product_type: "", title: "", items: [] }]);

	const populateData = () => {
		return axios.get(URI_ADMIN_PRODUCT, config)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					let data = response.data;
					setProducts(data);
					productsResultSet.current = data;
					let masterProducts = data.filter((p) => p.product_type).filter((p) => p.product_type.toUpperCase() === "SINGLE");
					localStorage.setItem("masterProducts", JSON.stringify(masterProducts));
				}
			}).catch((err) => {
				var error_received = "";		
				if (err.response) {
					error_received = err.response.data.detail;
				}
				console.log("my error: ", error_received);
				localStorage.setItem("masterProducts", JSON.stringify([]));
			})
	}

	const handleProductSearchTextChange = (text) => {
		let pds = productsResultSet.current;
		if (text) {
			let subset = pds.filter((p) => p.title ? p.title.toLowerCase().search(text.trim().toLowerCase()) > -1 : false);
			setProducts(subset);
		} else {
			setProducts(pds);
		}
	}

	const handleProductComboCreateClicked = () => {
		localStorage.setItem("productContext", JSON.stringify({items: [], product_type: "COMBO"}));
		navigate(PRODUCT_COMBOS);
	}

	const handleProductEditClicked = (index) => {
		let p = products[index];
		let pCache = {...p};
		if (!pCache.product_type) {
			pCache.product_type = "COMBO";
		}
		localStorage.setItem("productContext", JSON.stringify(pCache));
		navigate(PRODUCT_COMBOS);
	}

	const handleProductDeleteClicked = (id) => {
		startLoader();
		let promise = deleteProductCombo(id);
		promise.then((response) => {
			if (response.status === 200) {
				productsResultSet.current = productsResultSet.current.filter((p) => p.id !== id);
				setProducts(products.filter((p) => p.id !== id));
				setShowAlert(1);
			}
		}).catch((err)=> {
			console.log(err);
			setShowAlert(3);
		}).finally(() => stopLoader())
	}

	const deleteProductCombo = (id) => {
		return axios.delete(URI_ADMIN_PRODUCT + id, config);
	}

	const handleProductStatusChanged = (index) => {
		let pm = products[index];
		let newValue = !pm.active;
		startLoader();
		let promise = pm.active ? 
			axios.put(URI_ADMIN_PRODUCT_INACTIVE(pm.id),"", config) : 
			axios.put(URI_ADMIN_PRODUCT_ACTIVE(pm.id),"", config);
		promise.then((response) => {
			if (response.status === 200) {
				setProducts(products.map((p) => {
					return (p.id === pm.id) ? {...p, active: newValue} : p;
				}));
				setShowAlert(1);
			}
		}).catch((err)=> {
			console.log(err);
			setShowAlert(2);
		}).finally(() => stopLoader());
	}

	return (
		<>
		<Navbar />
		<div className="g-0 home-top-padding mb-4">
			<div>
				<Alert key='success' variant='success' show={showAlert === 1} dismissible onClose={() => setShowAlert(-1)}>
					Product updated successfully.
				</Alert>
				<Alert key='danger' variant='danger' show={showAlert === 2} dismissible onClose={() => setShowAlert(-1)}>
					Product update failed. Please try again after sometime.
				</Alert>
				<Alert key='warning' variant='warning' show={showAlert === 3} dismissible onClose={() => setShowAlert(-1)}>
					Product delete failed. Please delete dependent objects and try again.
				</Alert>
			</div>
			<div className="innerHead">
				<h1>Manage Products</h1>
			</div>
			<Container fluid="true" className="manageSOP">
			<Row>
				<Col>
				<Breadcrumb>
					<Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Products</Breadcrumb.Item>
				</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md={8}>
				<h4>Product List</h4>
				</Col>
				<Col md={4} className="text-end">
				<Button variant="primary" onClick={(e) => handleProductComboCreateClicked()}>
					Create Combo
				</Button>
				</Col>
			</Row>
			<Row className="formGroup mb-4 mt-3">
				<Col md={6}>
				<Row xs="auto">
					<Col className="d-flex">
					<Form.Control
						type="search"
						placeholder="Search by Product name"
						className="search-icon me-2"
						aria-label="Search"
						maxLength="50"
						onChange={(e) => handleProductSearchTextChange(e.target.value)}
					/>
					All prices are exclusive of GST
					</Col>
				</Row>
				</Col>
			</Row>
			<Row>
				<Col>
				<Table striped>
					<thead>
					<tr>
						<th>ID</th>
						<th>Product Name</th>
						<th>List Price</th>
						<th>Discounts (%)</th>
						<th>Sales Price</th>
						<th>Status</th>
						<th>Action</th>
					</tr>
					</thead>
					<tbody>
						{products.map((p, idx) => {
							return (
								<tr key={idx}>
									<td>{p.id}</td>
									<td>{p.title}</td>
									<td>{p.list_price}</td>
									<td>{p.discount_percentage}</td>
									<td>{p.effective_price}</td>
									<td>{p.product_type}</td>
									<td>
										<div className="d-flex">
											<Form.Check type="switch" id="custom-switch" checked={p.active} onChange={(e) => handleProductStatusChanged(idx)} />
											<Button variant="link" onClick={(e) => handleProductEditClicked(idx)}>
												<i className="bi bi-pencil"></i>
											</Button>{" "}
											<Button variant="link" onClick={(e) => handleProductDeleteClicked(p.id)}>
												<i className="bi bi-trash"></i>
											</Button>
										</div>
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
				</Col>
			</Row>
			</Container>
		</div>
		<Footer />
		</>
	);
}

export default ManageProducts;

import { Row, Col, Button, Container, Card, Form, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SOPExpiry from "../components/SOPExpiry";
import TopOrg from "../components/TopOrg";
import axios from "axios";
import {
	URI_ADMIN_REPORT_BY_NAME,
	URI_ADMIN_REPORT_LIST,
	URI_DASHBOARD_EARNINGS,
	URI_DASHBOARD_ORG_ADDITION,
	URI_DASHBOARD_SOP_NEAR_EXPIRY,
	URI_DASHBOARD_SOP_SOLD,
	URI_DASHBOARD_STAT,
	URI_DASHBOARD_TOP_ORG,
} from "../constants";
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";
import { useLoader } from "../hooks/useLoader";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	LineElement,
	PointElement,
	Title,
	Tooltip /* ,
	Legend */
);

function Home() {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	
	let dateRange = { fromDate: moment().date(1).format("YYYY-MM-DD"), toDate: moment().format("YYYY-MM-DD") };
	const dateRangeUrlParam = useRef(`?from_date=${dateRange.fromDate}&to_date=${dateRange.toDate}`);

	const [dashboardStat, setDashboardStat] = useState({
		no_of_companies: 0,
		no_of_subscribers: 0,
		no_of_bundles_sold: 0,
		no_of_users: 0,
	});
	const [topOrgs, setTopOrgs] = useState([]);
	const [sopNearExpiry, setSopNearExpiry] = useState([]);
	const [sopSold, setSopSold] = useState([]);
	const [orgAdditions, setOrgAdditions] = useState([]);
	const [earnings, setEarnings] = useState([]);

	const [reports, setReports] = useState([]);
	const [selectedReportIndex, setSelectedReportIndex] = useState(-1);
	const config = useRef({});

	const { startLoader, stopLoader } = useLoader();

	useEffect(() => {
		const authToken = localStorage.getItem("token");
		let idToken;
		if (authToken) {
			const { id_token } = JSON.parse(authToken);
			idToken = id_token;
		}
		config.current = {
			headers: {
				Authorization: "Bearer " + idToken,
			},
		};
		populateData();
	}, []);

	const populateData = () => {
		startLoader();
		let prs = [];
		prs.push(populateReportList());
		prs.push(populateDashboardStat());
		prs.push(populateTopOrgs());
		prs.push(populateSopSold());
		prs.push(populateOrgAddition());
		prs.push(populateEarnings());
		prs.push(populateSopNearExpiry());
		Promise.allSettled(prs).then(() => stopLoader());
	};

	const populateReportList = () => {
		return axios
			.get(URI_ADMIN_REPORT_LIST, config.current)
			.then((response) => {
				console.log(response);
				let s = [];
				if (response.status === 200) {
					s = response.data ? response.data : [];
				}
				setReports(s);
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
			});
	};
	const populateDashboardStat = () => {
		return axios.get(URI_DASHBOARD_STAT, config.current).then((response) => {
			let stat = {};
			if (response.status === 200) {
				response.data.map((s, idx) => {
					let k = s["key"];
					stat[k] = s["value"];
					return stat;
				});
			}
			setDashboardStat(stat);
		});
	};

	const populateTopOrgs = () => {
		return axios.get(URI_DASHBOARD_TOP_ORG, config.current).then((response) => {
			let orgs = [];
			if (response.status === 200) {
				orgs = response.data;
			}
			setTopOrgs(orgs);
		});
	};

	const populateSopSold = () => {
		return axios.get(URI_DASHBOARD_SOP_SOLD + dateRangeUrlParam.current, config.current).then((response) => {
			let ss = [];
			if (response.status === 200) {
				ss = response.data;
			}
			setSopSold(ss);
		});
	};

	const populateOrgAddition = () => {
		return axios.get(URI_DASHBOARD_ORG_ADDITION + dateRangeUrlParam.current, config.current).then((response) => {
			let ss = [];
			if (response.status === 200) {
				ss = response.data;
			}
			setOrgAdditions(ss);
		});
	};

	const populateEarnings = () => {
		return axios.get(URI_DASHBOARD_EARNINGS + dateRangeUrlParam.current, config.current).then((response) => {
			let ss = [];
			if (response.status === 200) {
				ss = response.data;
			}
			setEarnings(ss);
		});
	};

	const populateSopNearExpiry = () => {
		return axios.get(URI_DASHBOARD_SOP_NEAR_EXPIRY + dateRangeUrlParam.current, config.current).then((response) => {
			let ss = [];
			if (response.status === 200) {
				ss = response.data;
			}
			setSopNearExpiry(ss);
			// setSopNearExpiry([{company_name: "Abc pvt ltd", title: "HR", valid_till: "01-01-2024"}])
		});
	};

	const exportData = (data, fileName, type) => {
		// Create a link and download the file
		const blob = new Blob([data], { type });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	const handleCreateReportClick = () => {
		if (selectedReportIndex > -1 && selectedReportIndex < reports.length) {
			let { report_name } = reports[selectedReportIndex];
			let url = `${URI_ADMIN_REPORT_BY_NAME}?report_name=${report_name}`;
			axios
				.get(url, config.current)
				.then((response) => {
					if (response.status === 200) {
						console.log("report successful");
						exportData(response.data, "report.csv", "text/csv;charset=utf-8;");
					} else if (response.status >= 500 && response) {
						console.log(response.details);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const handleDateRangeChange = (dateRange) => {
		let camputeDR = { fromDate: moment().date(1).format("YYYY-MM-DD"), toDate: moment().format("YYYY-MM-DD") };

		if (dateRange === "1M") {
			camputeDR = { ...camputeDR, fromDate: moment().date(1).format("YYYY-MM-DD") };
		} else if (dateRange === "3M") {
			camputeDR = { ...camputeDR, fromDate: moment().date(1).subtract(3, "months").format("YYYY-MM-DD") };
		} else if (dateRange === "6M") {
			camputeDR = { ...camputeDR, fromDate: moment().date(1).subtract(6, "months").format("YYYY-MM-DD") };
		} else if (dateRange === "1Y") {
			camputeDR = { ...camputeDR, fromDate: moment().dayOfYear(1).format("YYYY-MM-DD") };
		} else if (dateRange === "2Y") {
			camputeDR = { ...camputeDR, fromDate: moment().dayOfYear(1).subtract(1, "years").format("YYYY-MM-DD") };
		}
		dateRangeUrlParam.current = `?from_date=${camputeDR.fromDate}&to_date=${camputeDR.toDate}`;

		console.log(dateRangeUrlParam.current);
		populateData();
	};

	const SopSoldBarChart = () => {
		let options = {
			responsive: true,
		};
		let d = {
			labels: sopSold.map((s) => s.month_year),
			datasets: [{ data: sopSold.map((s) => s.no_of_sop_sold), backgroundColor: "rgba(53, 162, 235, 0.5)" }],
		};
		return <Bar options={options} data={d} height={120} />;
	};
	const SopOrgAdditionBarChart = () => {
		let options = {
			responsive: true,
		};
		let d = {
			labels: orgAdditions.map((s) => s.month_year),
			datasets: [{ data: orgAdditions.map((s) => s.no_of_org_added), backgroundColor: "rgba(53, 162, 235, 0.5)" }],
		};
		return <Bar options={options} data={d} height={120} />;
	};

	const EarningsLineChart = () => {
		let options = {
			responsive: true,
		};
		let d = {
			labels: earnings.map((s) => s.month_year),
			datasets: [
				{
					data: earnings.map((s) => s.total_price),
					borderColor: "rgb(53, 162, 235)",
					backgroundColor: "rgba(53, 162, 235, 0.5)",
				},
			],
		};
		return <Line options={options} data={d} height={80} />;
	};

	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4 dashBoard">
				<div className="mt-3">
					<Container fluid="true" className="manageSOP">
						<Row className="mb-4">
							<Col md={7}>
								<h4 className="mt-1 mb-0">Overview</h4>
							</Col>
							<Col md={3}>
								<Form.Select onChange={(e) => handleDateRangeChange(e.target.value)}>
									<option key="1" value="1M">
										Current Month
									</option>
									<option key="2" value="3M">
										Last 3 Month
									</option>
									<option key="3" value="6M">
										Last 6 Month
									</option>
									<option key="4" value="1Y">
										Current Year
									</option>
									<option key="5" value="2Y">
										Last Year
									</option>
								</Form.Select>
							</Col>
							<Col md={2} className="text-end">
								<Button variant="primary" onClick={(e) => setShow(true)}>
									Generate Reports
								</Button>
							</Col>
						</Row>
						<Row className="mb-3 short-info">
							<Col>
								<Card>
									<Row>
										<Col className="icon">
											<i className="bi bi-people-fill"></i>
										</Col>
										<Col md={9}>
											<p className="mb-1 mt-1">No. of Register Users</p>
											<h4>{dashboardStat.no_of_users}</h4>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col className="ps-0">
								<Card>
									<Row>
										<Col className="icon">
											<i className="bi bi-box2-fill"></i>
										</Col>
										<Col md={9}>
											<p className="mb-1 mt-1">No. of Bundles Bought</p>
											<h4>{dashboardStat.no_of_bundles_sold}</h4>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col className="ps-0">
								<Card>
									<Row>
										<Col className="icon">
											<i className="bi bi-building-fill-check"></i>
										</Col>
										<Col md={9}>
											<p className="mb-1 mt-1">No. of Organisations</p>
											<h4>{dashboardStat.no_of_companies}</h4>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col className="ps-0">
								<Card>
									<Row>
										<Col className="icon">
											<i className="bi bi-person-heart"></i>
										</Col>
										<Col md={9}>
											<p className="mb-1 mt-1">No. of Subscribers</p>
											<h4>{dashboardStat.no_of_subscribers}</h4>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col md={8}>
								<Card className="chart">
									<h4>SOP Sold</h4>
									<SopSoldBarChart />
								</Card>
							</Col>
							<Col>
								<TopOrg orgs={topOrgs} />
							</Col>
						</Row>
						<Row className="mb-4">
							<Col md={8}>
								<Card className="chart">
									<h4>Organisation Additions</h4>
									<SopOrgAdditionBarChart />
								</Card>
							</Col>
							<Col>
								<SOPExpiry sops={sopNearExpiry} />
							</Col>
						</Row>
						<Row>
							<Col>
								<Card className="chart">
									<h4>Earnings</h4>
									<EarningsLineChart />
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			<Footer />

			<Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Generate Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form noValidate>
						<Form.Select
							aria-label="Default select example"
							value={selectedReportIndex}
							onChange={(e) => setSelectedReportIndex(e.target.value)}
						>
							<option key={-1} value={-1}>
								select report
							</option>
							{reports.map((rpt, idx) => {
								return (
									<option key={idx} value={idx}>
										{rpt.report_name}
									</option>
								);
							})}
						</Form.Select>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-primary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={(e) => handleCreateReportClick()}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Home;

import { useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { URL_VALIDATE_CONFIRMATION_CODE } from "./../../constants";
import Policies from "./../../components/Policies";

function ConfirmForgotPassword({ email, displayMessage, setCurrentStep }) {
	const navigate = useNavigate();
	const codeRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const username = email;
	console.log(displayMessage);

	const onSubmitForm = async (event) => {
		event.preventDefault();
		var data = {
			username: username,
			confirmation_code: codeRef.current.value,
			new_password: passwordRef.current.value,
		};
		if (passwordRef.current.value !== confirmPasswordRef.current.value) {
			displayMessage("danger", "New password and confirm password should match!");
			confirmPasswordRef.current.focus();
			return;
		}
		axios
			.post(URL_VALIDATE_CONFIRMATION_CODE, data)
			.then((response) => {
				//displayMessage("success", "password changed successfully!");
				//setCurrentStep(1);
				navigate("/success", {
					state: { id: 1, message: "Password changed successfully!. You can <a href='/login'>signin</a> now." },
				});
			})
			.catch((error) => {
				console.log(error);
				var error_received = "Error occured while changing the pasword.";
				if (error.response) {
					error_received = error.response.data.detail;
				}
				displayMessage("danger", error_received);
			});
	};

	return (
		<>
			<h4 className='text-center mb-3'>Change password</h4>
			<Form onSubmit={onSubmitForm}>
				<Form.Floating className='mb-3'>
					<Form.Control
						id='floatingInputCustom'
						type='text'
						placeholder='confirmation code'
						required
						maxLength={6}
						ref={codeRef}
					/>
					<label htmlFor='floatingInputCustom'>Confirmation code</label>
				</Form.Floating>
				<Form.Floating className='mb-3'>
					<Form.Control
						id='floatingPasswordCustom'
						type='password'
						placeholder='Password'
						required
						maxLength={50}
						ref={passwordRef}
					/>
					<label htmlFor='floatingPasswordCustom'>Password</label>
				</Form.Floating>
				<Form.Floating className='mb-3'>
					<Form.Control
						id='floatingPasswordCustom'
						type='password'
						placeholder='Confirm password'
						required
						maxLength={50}
						ref={confirmPasswordRef}
					/>
					<label htmlFor='floatingPasswordCustom'>Confirm password</label>
				</Form.Floating>
				<Policies />
				<Button variant='primary' className='w-100' type='submit'>
					Continue
				</Button>
			</Form>
		</>
	);
}

export default ConfirmForgotPassword;

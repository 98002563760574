import { useState } from "react";
import { Container, Card, Alert } from "react-bootstrap";
import { ImgLogo } from "./../../components/Import";
import GenerateCode from "./GenerateCode";
import ConfirmForgotPassword from "./ConfirmForgotPassword";

function ForgotPassword() {
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState("");
	const [alertVariant, setAlertVariant] = useState("danger");
	const [message, setMessage] = useState("");

	function showStep2(email) {
		setEmail(email);
		setCurrentStep(2);
	}

	function setCurrentStep(step) {
		setStep(step);
	}

	const displayMessage = function (variant, message) {
		setMessage(message);
		setAlertVariant(variant);
	};

	return (
		<Container fluid='true' className='login-bg position-relative'>
			<Card className='login'>
				<div className='position-absolute back-btn'>
					<a href='/login'>
						<i className='bi bi-arrow-left-short'></i>
					</a>
				</div>
				<div className='login-logo'>
					<img className='d-block' src={ImgLogo} alt='mySOP guide' />
				</div>
				{message !== "" && (
					<Alert variant={alertVariant} dismissible>
						{message}
					</Alert>
				)}
				{step === 1 && <GenerateCode showStep2={showStep2} displayMessage={displayMessage} />}
				{step === 2 && (
					<ConfirmForgotPassword email={email} displayMessage={displayMessage} setCurrentStep={setCurrentStep} />
				)}
			</Card>
		</Container>
	);
}
export default ForgotPassword;

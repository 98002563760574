import { Row, Col, Container, Breadcrumb, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import { URI_ADMIN_REPORT_ORG_BUNDLE_SUMMARY } from "../../constants";
import axios from "axios";
import { Table } from "antd";
import { minutesToHours } from "../../utility/utils";
import { useLoader } from "../../hooks/useLoader";

function OrganizationReport() {
	const [reportData, setReportData] = useState([]);
	const { startLoader, stopLoader } = useLoader();
	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};

	useEffect(() => {
		startLoader();
		let prs = [];
		prs.push(populateData());
		Promise.allSettled(prs).then(() => stopLoader())
	}, []);

	const populateData = () => {
		return axios
		.get(URI_ADMIN_REPORT_ORG_BUNDLE_SUMMARY, config)
		.then((response) => {
			var data = response.data;
			const groupedData = {};
			// Loop through the data and group objects by sop_bundle_title
			data.forEach((item) => {
				item.usage_min = minutesToHours(item.usage_min);
				const { company_name, ...rest } = item;
				if (!groupedData[company_name]) {
					groupedData[company_name] = [];
				}
				groupedData[company_name].push(rest);
			});

			// Convert the grouped data into an array of objects with children property
			const result = Object.keys(groupedData).map((company_name) => ({
				company_name,
				children: groupedData[company_name],
			}));

			setReportData(result);
		})
		.catch((error) => console.error(error));
	}

	const columns = [
		{
			title: "Organization",
			dataIndex: "company_name",
			key: "company_name",
		},
		{
			title: "Category",
			dataIndex: "sop_bundle_title",
			key: "sop_bundle_title",
		},
		{
			title: "Usage",
			dataIndex: "usage_min",
			key: "usage_min",
		},
		{
			title: "Clicks",
			dataIndex: "no_of_clicks",
			key: "no_of_clicks",
		}
	];

	return (
		<>
			<Navbar />
			<div className='g-0 home-top-padding mb-4'>
				<div className='innerHead'>
					<h1>Reports</h1>
				</div>
				<Container fluid='true' className='manageSOP'>
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href='/Home'>Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Reports - Bundle Usage & Views - Organization Wise</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<h4>Bundle Usage & Views - Organization Wise</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table columns={columns} dataSource={reportData} />
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</>
	);
}

export default OrganizationReport;

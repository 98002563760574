import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export const TopOrg = ({orgs}) => {
	return (
		<Card className="top-sops">
			<Container>
				<Row className="mb-3">
					<Col xs={7}>
						<h4>Top Organisation</h4>
					</Col>
					{/* <Col xs={5} className="text-end">
						<Card.Link href="#" className="float-end mt-1">
							View All
						</Card.Link>
					</Col> */}
				</Row>
				{orgs.map((o, idx) => {
					return (
						<Row key={idx}>
							<Col xs={1}>
								<i className="bi bi-file-earmark-text"></i>
							</Col>
							<Col xs={7} className="mt-3">
								<div className="head">
									<h5 className="mb-0">{o.company_name}</h5>
								</div>
							</Col>
							<Col xs={4} className="mt-3 text-end">
								{o.sop_purchased} Purchases
							</Col>
							<hr></hr>
						</Row>
					)
				})}
				{/* <Row>
					<Col xs={1}>
						<i className="bi bi-file-earmark-text"></i>
					</Col>
					<Col xs={7} className="mt-3">
						<div className="head">
							<h5 className="mb-0">{orgs.company_name}</h5>
						</div>
					</Col>
					<Col xs={4} className="mt-3 text-end">
						{orgs.sop_purchased} Purchases
					</Col>
				</Row>
				<hr></hr> */}
				{/* <Row>
					<Col xs={1}>
						<i className="bi bi-file-earmark-text"></i>
					</Col>
					<Col xs={7} className="mt-3">
						<div className="head">
							<h5 className="mb-0">Digital Consulting</h5>
						</div>
					</Col>
					<Col xs={4} className="mt-3 text-end">
						10 Purchases
					</Col>
				</Row>
				<hr></hr>
				<Row>
					<Col xs={1}>
						<i className="bi bi-file-earmark-text"></i>
					</Col>
					<Col xs={7} className="mt-3">
						<div className="head">
							<h5 className="mb-0">Digital Consulting</h5>
						</div>
					</Col>
					<Col xs={4} className="mt-3 text-end">
						10 Purchases
					</Col>
				</Row>
				<hr></hr>
				<Row>
					<Col xs={1}>
						<i className="bi bi-file-earmark-text"></i>
					</Col>
					<Col xs={7} className="mt-3">
						<div className="head">
							<h5 className="mb-0">Digital Consulting</h5>
						</div>
					</Col>
					<Col xs={4} className="mt-3 text-end">
						10 Purchases
					</Col>
				</Row>
				<hr></hr>
				<Row>
					<Col xs={1}>
						<i className="bi bi-file-earmark-text"></i>
					</Col>
					<Col xs={7} className="mt-3">
						<div className="head">
							<h5 className="mb-0">Digital Consulting</h5>
						</div>
					</Col>
					<Col xs={4} className="mt-3 text-end">
						10 Purchases
					</Col>
				</Row> */}
			</Container>
		</Card>
	);
};
export default TopOrg;

import React from "react";

export const Page404 = () => {
	return (
		<div>
			<h2>404 - Not found</h2>
		</div>
	);
};

export default Page404;

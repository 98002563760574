import { Row, Col, Card, Button, Container, Breadcrumb, Form, Table } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import { useLoader } from "../../hooks/useLoader";
import axios from "axios";
import { URI_ADMIN_REPORT_LOGIN_SUMMARY } from "../../constants";

import moment from 'moment-timezone';

const ALERT_CODES = {
	HIDE_ALERT_CODE : -1,
	SUCCESS_CODE : 1,
	FAILURE_CODE : 2
}

function LoginSummary() {

	const [showAlert, setShowAlert] = useState(ALERT_CODES.HIDE_ALERT_CODE);
	const { startLoader, stopLoader } = useLoader();
	const config = useRef({});

	const [loginSummary, setLoginSummary] = useState([]);
	const loginSumResultset = useRef([]);

	const [lastLoginAsc, setLastLoginAsc] = useState(true);

	useEffect(() => {
		let idToken;
		const authToken = localStorage.getItem("token");
		if (authToken) {
			const { id_token } = JSON.parse(authToken);
			idToken = id_token;
		}
		config.current = {
			headers: {
				Authorization: "Bearer " + idToken,
			},
		};
		populateData();
	}, []);

	const populateData = () => {
		startLoader();
		let prs = [];
		prs.push(populateLoginSummary());
		Promise.allSettled(prs).then(() => stopLoader())
	}

	function populateLoginSummary() {
		return axios.get(URI_ADMIN_REPORT_LOGIN_SUMMARY, config.current)
		.then((response) => {
		  console.log(response);
		  if (response.status === 200) {
			  let sumry = response.data;
			  setLoginSummary(sumry);
			  loginSumResultset.current = sumry;
		  } else {
			console.log("error loading login summary.")
			console.log(response);
		  }
		})
	  .catch((err) => {
		  var error_received = "";
		  if (err.response) {
			  error_received = err.response.data.detail;
		  }
		  console.log("error: ", error_received);
	  });
	}

	const handleLoginReportSearchChange = (text) => {
		let subs = loginSumResultset.current;
		if (text) {
			let subset = subs.filter((s) => s.email.toLowerCase().search(text.trim().toLowerCase()) > -1);
			setLoginSummary(subset);
		} else {
			setLoginSummary(subs);
		}
	};

	const handleLastLoginSortClick = () => {
		console.log("sort order : " + lastLoginAsc);
		let usr = loginSumResultset.current;
		// let subs = loginSumResultset.current;
		let sorted;
		if (lastLoginAsc) {
			sorted = usr.sort((u1, u2) => u1.last_login_at < u2.last_login_at ? 1 : -1 );
		} else {
			sorted = usr.sort((u1, u2) => u1.last_login_at > u2.last_login_at ? 1 : -1);
		}
		setLoginSummary(sorted);
		setLastLoginAsc(!lastLoginAsc);
	}

	return (
		<>
			<Navbar />
			<div className="g-0 home-top-padding mb-4">
				<div className="innerHead">
					<h1>Reports</h1>
				</div>
				<Container fluid="true" className="manageSOP">
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href="/Home">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Reports - Login Summary</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<h4>Login Summary</h4>
						</Col>
					</Row>
					<Row className="formGroup mb-4 mt-3">
						<Col md={6}>
							<Row xs="auto">
								<Col>
									<Form.Control
										type="search"
										placeholder="Search by email"
										className="search-icon me-2"
										aria-label="Search"
										onChange={(e) => handleLoginReportSearchChange(e.target.value)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped>
								<thead>
									<tr>
										<th>User</th>
										<th>Username</th>
										<th>Email</th>
										<th>Last Login <Button variant="border" onClick={() => handleLastLoginSortClick()}>{ lastLoginAsc ? <code>&#65514;</code> : <code>&#65516;</code> }</Button></th>
										<th>IP Address of last Login</th>
									</tr>
								</thead>
								<tbody>
									{loginSummary.map((sumry) => {
										return (
										<tr>
											<td>{sumry.company_name}</td>
											<td>{sumry.user_name}</td>
											<td>{sumry.email}</td>
											<td>{sumry.last_login_at ? moment.utc(sumry.last_login_at).tz("Asia/Kolkata").format('DD-MM-YYYY, h:mm:ss a') : "" }</td>
											<td>{sumry.last_login_ip_address}</td>
										</tr>
										)
									})}
									{/* <tr>
										<td>Somanth</td>
										<td>somnaths</td>
										<td>abc@test.com</td>
										<td>1</td>
										<td>10-04-2023 8:30am</td>
									</tr>
									<tr>
										<td>Somanth</td>
										<td>somnaths</td>
										<td>abc@test.com</td>
										<td>1</td>
										<td>10-04-2023 8:30am</td>
									</tr>
									<tr>
										<td>Somanth</td>
										<td>somnaths</td>
										<td>abc@test.com</td>
										<td>1</td>
										<td>10-04-2023 8:30am</td>
									</tr>
									<tr>
										<td>Somanth</td>
										<td>somnaths</td>
										<td>abc@test.com</td>
										<td>1</td>
										<td>10-04-2023 8:30am</td>
									</tr>
									<tr>
										<td>Somanth</td>
										<td>somnaths</td>
										<td>abc@test.com</td>
										<td>1</td>
										<td>10-04-2023 8:30am</td>
									</tr>
									<tr>
										<td>Somanth</td>
										<td>somnaths</td>
										<td>abc@test.com</td>
										<td>1</td>
										<td>10-04-2023 8:30am</td>
									</tr> */}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</>
	);
}

export default LoginSummary;

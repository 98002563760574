export function minutesToHours(minutes) {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;

	if (hours === 0) {
		return `${remainingMinutes} minutes`;
	} else {
		return `${hours} hours ${remainingMinutes} minutes`;
	}
}

import axios from "axios";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { URI_ADMIN_COUPONS } from "../../constants";
import moment from "moment";

function CouponDetails({show, setShow, coupon, setCoupon, setShowAlert, ALERT_CODES, reloadCoupons}) {

	const authToken = localStorage.getItem("token");
	let idToken;
	if (authToken) {
		const { id_token } = JSON.parse(authToken);
		idToken = id_token;
	}
	const config = {
		headers: {
			Authorization: "Bearer " + idToken,
		},
	};
	const handleClose = () => {setShow(false); setShowAlert(ALERT_CODES.HIDE_ALERT_CODE)}

	const handleSaveClicked = () => {
		let payload = {...coupon, utilized_tokens: 0};
		let from = new Date(coupon.valid_from);
		payload.valid_from = moment(from).format('YYYY-MM-DD') + 'T00:00:00' ;
		let till = new Date(coupon.valid_till);
		payload.valid_till = moment(till).format('YYYY-MM-DD') + 'T00:00:00' ;
		console.log(payload);
		let promise = (coupon && coupon.id && coupon.id > 0) ? axios.put(URI_ADMIN_COUPONS + "/" + coupon.id, payload, config) : axios.post(URI_ADMIN_COUPONS, payload, config);
		promise.then((response) => {
			console.log(response);
			if (response.status === 200) {
				setShowAlert(ALERT_CODES.SUCCESS_CODE);
			} else {
				setShowAlert(ALERT_CODES.FAILURE_CODE);
			}
		}).catch((err) => {
			console.log(err); 
			setShowAlert(ALERT_CODES.FAILURE_CODE);
		}).finally(() => { setShow(false); reloadCoupons()}) ;
	}
	
	return (
		<Modal show={show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>Add Coupon</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* <div>
					<Alert key="success" variant="success" show={showAlert === SUCCESS_CODE} dismissible onClose={() => setShowAlert(HIDE_ALERT_CODE)} >
						Transaction successful !
					</Alert>
					<Alert key="danger" variant="danger" show={showAlert === FAILURE_CODE} dismissible onClose={() => setShowAlert(HIDE_ALERT_CODE)} >
						Transaction failed !
					</Alert>
				</div> */}
				<Form>
					<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="firstname">
								<Form.Label className="d-none">Code</Form.Label>
								<Form.Control type="text" placeholder="First Name" value={coupon.code_text} onChange={(e) => setCoupon({...coupon, code_text: e.target.value})} />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3">
								<Form.Label className="d-none">Start Date</Form.Label>
								<Form.Control type="text" placeholder="Start Date (YYYY-MM-DD)" value={coupon.valid_from} onChange={(e) => setCoupon({...coupon, valid_from: e.target.value})} />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3">
								<Form.Label className="d-none">End Date</Form.Label>
								<Form.Control type="text" placeholder="End Date (YYYY-MM-DD)" value={coupon.valid_till} onChange={(e) => setCoupon({...coupon, valid_till: e.target.value})} />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="usages">
								<Form.Label className="d-none">Maximum Successful Usages</Form.Label>
								<Form.Control type="text" placeholder="Maximum Successful Usages" value={coupon.max_tokens} onChange={(e) => setCoupon({...coupon, max_tokens: e.target.value})} />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="discount">
								<Form.Label className="d-none">Discount</Form.Label>
								<Form.Control type="text" placeholder="Discount" value={coupon.discount} onChange={(e) => setCoupon({...coupon, discount: e.target.value})} />
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-primary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={(e) => handleSaveClicked()}>
					{coupon ? "Save" : "Create"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default CouponDetails;
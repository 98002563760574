function Policies() {
  return (
    <div className="mt-3 login-font mb-3">
      By continuing, you agree to mySOPguide{" "}
      <a href="/terms-and-conditions" title="Terms and conditions">
        Terms
      </a>{" "}
      and{" "}
      <a href="/privacy-policy" title="Privacy policy">
        Privacy policy
      </a>
    </div>
  );
}

export default Policies;

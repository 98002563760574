import { startTransition, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { ImgLogo } from "../components/Import";
import { Container, Card } from "react-bootstrap";

function SuccessPage() {
	const location = useLocation();
	console.log(location);
	const navigate = useNavigate();
	const [message, setMessage] = useState(null);

	// useEffect(() => {
	// 	console.log(location.state);
	// 	if (location.state == null) {
	// 		startTransition(() => {
	// 			navigate({ pathname: "/home" });
	// 		});
	// 	} else {
	// 		setMessage(location.state.message);
	// 	}
	// }, []);

	return (
		<Container fluid='true' className='login-bg position-relative'>
			<Card className='login'>
				<div className='login-logo'>
					<img className='d-block' src='' alt='mySOP guide' />
				</div>
				<h4 variant='success' className='text-center mb-3'>
					Success !
				</h4>
				<p>
					<div dangerouslySetInnerHTML={{ __html: message }}></div>
				</p>
			</Card>
		</Container>
	);
}

export default SuccessPage;
